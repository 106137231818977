import { createSelector } from 'reselect';

export const getMyShowingsState = (state: any) => state.myShowings || {};

export const getMyShowings = createSelector(
    getMyShowingsState,
    (myShowings) => myShowings.myShowings || {},
);

export const getLoading = createSelector(
    getMyShowingsState,
    (myShowings) => myShowings.loading || null,
);

export const getShowingActionComplete = createSelector(
    getMyShowingsState,
    (myShowings) => myShowings.showingActionComplete,
);
