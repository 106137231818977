import * as LISTING_ACTIONS from './actions';

export const initialState = {
    loading: false,
    listings: [],
    errors: [],
    // availability
    availability: {},
    availabilityLoading: false,
    // new rule
    createRuleLoading: false,
    // selected listing for configuration
    selectedListing: null,
};

export default function (state = initialState, action: any) {
    const { type } = action;
    switch (type) {
        case LISTING_ACTIONS.FETCH_LISTINGS_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case LISTING_ACTIONS.FETCH_LISTINGS_SUCCEEDED:
            const { listings } = action;
            return {
                ...state,
                listings,
                loading: false,
            };
        case LISTING_ACTIONS.FETCH_LISTINGS_FAILED:
            return {
                ...state,
                errors: action.errors,
                loading: false,
            };
        // FETCH_AVAILABILITY
        case LISTING_ACTIONS.FETCH_AVAILABILITY_REQUESTED:
            return {
                ...state,
                availabilityLoading: true,
            };
        case LISTING_ACTIONS.FETCH_AVAILABILITY_SUCCEEDED:
            const { availability } = action;
            return {
                ...state,
                availabilityLoading: false,
                availability,
            };
        case LISTING_ACTIONS.FETCH_AVAILABILITY_FAILED:
            return {
                ...state,
                errors: action.errors,
                availabilityLoading: false,
            };
        // CREATE_LISTING_RULE
        case LISTING_ACTIONS.CREATE_LISTING_RULE_REQUESTED:
            return {
                ...state,
                createRuleLoading: true,
            };
        case LISTING_ACTIONS.CREATE_LISTING_RULE_SUCCEEDED:
            return {
                ...state,
                createRuleLoading: false,
            };
        case LISTING_ACTIONS.CREATE_LISTING_RULE_FAILED:
            return {
                ...state,
                errors: action.errors,
                createRuleLoading: false,
            };
        // SET_SELECTED_LISTING
        case LISTING_ACTIONS.SET_SELECTED_LISTING:
            return {
                ...state,
                selectedListing: action.listing,
            };
        // CLEAR_AVAILABILITY
        case LISTING_ACTIONS.CLEAR_AVAILABILITY:
            return {
                ...state,
                availability: {},
            };
        // CLEAR_SUCCESS_MARKER
        case LISTING_ACTIONS.CLEAR_SUCCESS_MARKER:
            return {
                ...state,
                configSuccess: false,
            };
        // LISTING_CONFIG_REQUESTED
        case LISTING_ACTIONS.LISTING_CONFIG_REQUESTED:
            return {
                ...state,
                loading: true,
                configSuccess: false,
            };
        case LISTING_ACTIONS.LISTING_CONFIG_SUCCEEDED:
            return {
                ...state,
                loading: false,
                configSuccess: true,
            };
        case LISTING_ACTIONS.LISTING_CONFIG_FAILED:
            return {
                ...state,
                errors: action.errors,
                loading: false,
                configSuccess: true,
            };
        default:
            return state;
    }
}
