export const DEFAULT_PROFILE_FIELDS = [
    { field: 'listAgentMlsId', displayName: 'Listing Agent Mls Id', required: true },
    { field: 'listAgentName', displayName: 'Listing Agent Name', required: true },
    {
        field: 'listAgentLicenseNumber',
        displayName: 'Listing Agent License Number',
        required: true,
    },
    {
        field: 'listingAgentLicenseState',
        displayName: 'Listing Agent License State',
        required: true,
    },
];

export const DEFAULT_INPUT_FIELDS = [
    { field: 'listingId', displayName: 'Listing Id', required: true },
    { field: 'universalPropertyId', displayName: 'Universal Property Id', required: true },
    { field: 'address1', displayName: 'Address', required: true },
    { field: 'city', displayName: 'City', required: true },
    { field: 'state', displayName: 'State', required: true },
    { field: 'zipCode', displayName: 'Zip Code', required: true },
    // { field: 'listAgentMlsId', displayName: 'Listing Agent Mls Id', required: true },
    // { field: 'listAgentName', displayName: 'Listing Agent Name', required: true },
    // {
    //     field: 'listAgentLicenseNumber',
    //     displayName: 'Listing Agent License Number',
    //     required: true,
    // },
    // {
    //     field: 'listingAgentLicenseState',
    //     displayName: 'Listing Agent License State',
    //     required: true,
    // },
    { field: 'showingInstructions', displayName: 'Showing Instructions', required: false },
    { field: 'comments', displayName: 'Comments', required: false },
    { field: 'address2', displayName: 'Address 2', required: false },
];

export const DEFAULT_ENUM_FIELDS = [
    {
        field: 'listAgentLicenseStateAffirmation',
        displayName: 'Listing Agent License State Affirmation',
        enum: [
            { field: true, displayName: 'True' },
            { field: false, displayName: 'False' },
        ],
        required: true,
    },
    {
        field: 'requiredParticipants',
        displayName: 'Required Participants',
        enum: [
            { field: 'ListingAgent', displayName: 'Listing Agent' },
            { field: 'BuyingAgent', displayName: 'Buying Agent' },
            { field: 'BothBuyingAndListingAgent', displayName: 'Both Buying and Listing Agent' },
            { field: 'NoParticipants', displayName: 'No Participants' },
        ],
        required: true,
    },
    {
        field: 'showingMethod',
        displayName: 'Showing Method',
        enum: [
            { field: 'InPersonOnly', displayName: 'In Person Only' },
            { field: 'VirtualOnly', displayName: 'Virtual Only' },
            { field: 'InPersonAndVirtual', displayName: 'In Person and Virtual' },
        ],
        required: true,
    },
    {
        field: 'confirmationType',
        displayName: 'Confirmation Type',
        enum: [
            { field: 'AutoApprove', displayName: 'Auto Approve' },
            { field: 'ConfirmationRequired', displayName: 'Confirmation Required' },
            { field: 'ShowingInstructionsOnly', displayName: 'Showing Instructions Only' },
        ],
        required: true,
    },
    // {
    //     field: 'showingsAllowed',
    //     displayName: 'Showings Allowed',
    //     enum: [
    //         { field: 'true', displayName: 'True' },
    //         { field: 'false', displayName: 'False' },
    //     ],
    //     required: true,
    // },
    {
        field: 'showingStatus',
        displayName: 'Showing Status',
        enum: [
            { field: 'Showable', displayName: 'Showable' },
            { field: 'NotShowable', displayName: 'Not Showable' },
            // { field: 'Suspended', displayName: 'Suspended' },
        ],
        required: true,
    },
];

export const DEFAULT_PICKER_FIELDS = [
    { field: 'showableStartDate', displayName: 'Showable Start Date', required: true },
    { field: 'showableEndDate', displayName: 'Showable End Date', required: true },
];

export const DROP_DOWN_FIELDS = [{ field: 'seller', displayName: 'Seller', required: false }];

export const APPLICATION_ID = 'cda586ba-5ef5-4309-8259-08d98ab1abea';

export const LISTING_TEST_FIELDS = {
    listingId: '1234514',
    universalPropertyId: '1f1f4',
    address1: 'TEST TEST',
    city: 'Denver',
    state: 'CO',
    zipCode: '80202',
    listAgentMlsId: 'zrick913',
    listAgentName: 'Z Rick',
    listAgentLicenseStateAffirmation: true,
    listAgentLicenseNumber: '123454321',
    listingAgentLicenseState: 'Colorado',
    showableStartDate: '2021-10-11T00:00:00+00:00',
    showableEndDate: '2021-10-27T00:00:00+00:00',
    requiredParticipants: 'ListingAgent',
    showingMethod: 'InPersonOnly',
    confirmationType: 'ConfirmationRequired',
    // showingAllowed: 'showable',
    showingStatus: 'Showable',
    showingInstructions: 'Take shoes off please',
    comments: 'no comments for me',
    address2: '',
};
