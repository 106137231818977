import * as Realm from 'realm-web';
import { USERS_COLLECTION } from '../constants';

/**
 * Call the given stitch function with its params
 * @param client Mongo Stitch Client returned from initialization
 * @param endpoint
 * @param params
 * @returns {Promise<*>}
 */
export function callStitchFunction(endpoint, ...params) {
    const client = Realm.App.getApp(process.env.REACT_APP_STITCH_APP_ID);
    return client.currentUser.functions.callFunction(
        endpoint,
        ...params.map((d) => (d ? d : undefined)),
    );
}

/**
 * Anonymous stitch login so we can gain access to specific functions
 * @returns {Promise<StitchAppClient>}
 */
export async function anonLogin() {
    const client = new Realm.App(process.env.REACT_APP_STITCH_APP_ID);
    const credentials = Realm.Credentials.anonymous();
    const user = await client.logIn(credentials);
    return user;
}

export async function getCollection(collectionName) {
    const client = new Realm.App(process.env.REACT_APP_STITCH_APP_ID);
    const currentUser = client.currentUser ?? (await anonLogin());
    const mongoClient = currentUser.mongoClient(process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME);
    const db = mongoClient.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    return db.collection(collectionName);
}

export async function insertNewUser(args) {
    const users = await getCollection('users');
    const existingPhone = await users.findOne({
        phoneNumber: args.phoneNumber,
    });
    if (existingPhone) {
        throw new Error('Phone number already in use');
    }
    return await users.insertOne(args);
}

/**
 * Creates a new Stitch User via email & password
 * @param {string} email
 * @param {string} password
 * @returns {Promise<boolean>}
 */
export async function requestUserRegistration(email, password) {
    const client = new Realm.App(process.env.REACT_APP_STITCH_APP_ID);
    return client.emailPasswordAuth.registerUser(email, password);
}

export async function login(email, password) {
    const client = new Realm.App(process.env.REACT_APP_STITCH_APP_ID);
    const credentials = Realm.Credentials.emailPassword(email, password);
    const user = await client.logIn(credentials);
    return { client, user };
}

/**
 * Logout the current signed in user from Stitch.
 * This is applicable for both an anonymous logged in user and
 * a user logged in via email/password.
 *
 * @returns {Promise<void>}
 */
export async function logout() {
    const client = new Realm.App(process.env.REACT_APP_STITCH_APP_ID);
    return client.currentUser.logOut();
}

/**
 * Gets the user's record associated to the given realm user, if any.
 *
 * @param user
 * @returns {Promise<Document | null>}
 */
export async function getUserRecord(userId) {
    const users = await getCollection('users');
    const query = { realmUserId: userId };
    return await users.findOne(query);
}

/**
 * Update main user's collection document
 * @param {realmUserId} realm user id of the user
 * @param {setData} object fields to update
 */
export async function updateUserDoc(realmUserId, setData) {
    const users = await getCollection(USERS_COLLECTION);
    const options = { upsert: true };
    return await users.updateOne({ realmUserId }, { $set: setData }, options);
}

/**
 * Update main user's collection document
 * @param {clientId} objectId of the consumer
 * @param {requestId} string showing request id
 */
export async function addShowingToClient(clientId, requestId) {
    const consumers = await getCollection('consumers');
    return await consumers.updateOne(
        { _id: new Realm.BSON.ObjectID(clientId) },
        { $push: { associatedShowings: requestId } },
    );
}

/**
 * Gets the user's record associated to the given realm user, if any.
 *
 * @param objectId
 * @returns {Promise<Document | null>}
 */
export async function findMyConsumers(objectId) {
    const consumers = await getCollection('consumers');
    const query = { agentId: objectId };
    return await consumers.find(query);
}

/**
 * Gets the user's record associated to the given realm user, if any.
 *
 * @param objectId
 * @returns {Promise<Document | null>}
 */
export async function findShowingConsumer(requestId) {
    const consumers = await getCollection('consumers');
    const query = { associatedShowings: requestId };
    return await consumers.findOne(query);
}
