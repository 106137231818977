import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducer';
import sagas from './saga';

const sagaMiddleware = createSagaMiddleware();

var middleware = [sagaMiddleware];

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

sagaMiddleware.run(sagas);

const Store = ({ children }) => {
    return <Provider store={store}>{children}</Provider>;
};

export { store };

export default Store;
