import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import {
    getUserRecord,
    insertNewUser,
    login,
    logout,
    requestUserRegistration,
    updateUserDoc,
} from '../../store/api/sagas';
import { STATUS } from '../../utils/common/constants';
import {
    AUTH_ACTION,
    createNewUserFailed,
    createNewUserRequested,
    createNewUserSucceeded,
    initializeFailed,
    initializeRequested,
    initializeSucceeded,
    setClient,
    setRealmUserId,
    setUserRecord,
    userLoginFailed,
    userLoginRequested,
    userLoginSucceeded,
    userLogoutRequested,
} from './actions';
import * as Realm from 'realm-web';
import { getRealmUserId } from './selectors';
import { select } from 'redux-saga/effects';

export function* initialize(): Generator<any, any, any> {
    try {
        const realmAppId: string =
            process.env.REACT_APP_STITCH_APP_ID ||
            'placeholder: nobody on earth will have this realm app id';
        const stitchClient = new Realm.App(realmAppId);
        const { currentUser } = stitchClient;
        if (currentUser?.isLoggedIn && currentUser.providerType !== 'anon-user') {
            yield put(initializeSucceeded(currentUser?.isLoggedIn));

            // load client data and profile data on page reload
            yield put(setClient(stitchClient));
            yield put(setRealmUserId(currentUser?.id));
            const userRecord = yield call(getUserRecord, currentUser.id);
            yield put(setUserRecord(userRecord));
        } else {
            yield put(initializeSucceeded(false));
        }
    } catch (err) {
        yield put(initializeFailed());
    }
}

export function* createNewUser({
    user,
}: ReturnType<typeof createNewUserRequested>): Generator<any, any, any> {
    try {
        yield call(requestUserRegistration, user.email, user.password);
        const newRealmUser = yield call(login, user.email, user.password);
        const {
            user: { id = null },
            client,
        } = newRealmUser;
        if (id) user.realmUserId = id;
        delete user.password;
        const minutesUtcOffset = new Date().getTimezoneOffset();
        const utcOffset = Math.floor(minutesUtcOffset / 60);
        const { insertedId } = yield call(insertNewUser, { ...user, utcOffset });
        localStorage.setItem('currentUserObjectIdAsString', JSON.stringify(insertedId));
        yield put(setClient(client));
        yield put(setRealmUserId(id));
        yield put(createNewUserSucceeded());
    } catch (err) {
        yield put(createNewUserFailed(err));
    }
}

export function* userLogin({
    userInfo,
}: ReturnType<typeof userLoginRequested>): Generator<any, any, any> {
    try {
        const realmInformation = yield call(login, userInfo.email, userInfo.password);
        const {
            user: { id = null },
            client = null,
        } = realmInformation;
        if (id && client) {
            yield put(setClient(client));
            yield put(setRealmUserId(id));
            const userRecord = yield call(getUserRecord, id);
            const { _id = 'Placeholder: not a real objectId' } = userRecord;
            yield put(setUserRecord(userRecord));
            localStorage.setItem('currentUserObjectIdAsString', JSON.stringify(_id));
            const realmUserId = yield select(getRealmUserId);
            const minutesUtcOffset = new Date().getTimezoneOffset();
            const utcOffset = Math.floor(minutesUtcOffset / 60);
            yield call(updateUserDoc, realmUserId, {
                utcOffset,
            });
            yield put(userLoginSucceeded());
        } else {
            yield put(userLoginFailed('Email or Password incorrect'));
        }
    } catch (err: any) {
        yield put(userLoginFailed(err.error || 'Invalid Username/Password'));
    }
}

export function* userLogout({}: ReturnType<typeof userLogoutRequested>): Generator<any, any, any> {
    try {
        yield call(logout);
        yield put(setUserRecord(null));
        yield put(setClient(null));
        yield put(setRealmUserId(null));
        localStorage.removeItem('currentUserObjectIdAsString');
        yield put(initializeRequested());
    } catch (err) {
        yield put(initializeFailed());
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Initialize && action.status === STATUS.Requested,
            initialize,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.Create && action.status === STATUS.Requested,
            createNewUser,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.UserLogin && action.status === STATUS.Requested,
            userLogin,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AUTH_ACTION.UserLogout && action.status === STATUS.Requested,
            userLogout,
        ),
    ]);
}
