import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConnectedSideBar } from './SideBar';

import styles from './main-app.module.css';
import ListingSearchScreen from '../domains/ListingSearch/ListingSearchScreen';
import ScheduleScreen from '../domains/ListingSearch/ScheduleScreen';
import { ConnectedListingsScreen } from '../domains/listings/ConnectedListingsScreen';
import { ConnectedListingsConfigScreen } from '../domains/listings/ConnectedListingsConfigScreen';
import MyShowingsScreen from '../domains/myShowings/MyShowingsScreen';
import { getUser } from '../domains/auth/selectors';
import { ConnectedClientsScreen } from '../domains/clients/ConnectedClientsScreen';
import { ConnectedAddClient } from '../domains/clients/ConnectedAddClient';
import { userLogoutRequested } from '../domains/auth/actions';

function MainApp({ user, logOutRequested }) {
    const history = useHistory();

    // no matter what, when the app refreshes or the user logs in, send the user to the dashboard
    useEffect(() => {
        history.push('/home');
    }, [history]);

    let listItems = [
        {
            link: '/home',
            pageTitle: 'Search',
        },
        {
            link: '/myShowings',
            pageTitle: 'Showings',
        },
    ];

    if (user?.userType === 'agent') {
        listItems.push(
            {
                link: '/listings',
                pageTitle: 'Listings',
            },
            {
                link: '/clients',
                pageTitle: 'Clients',
            },
        );
    }

    const Sidebar = () => (
        <div className={styles.sidebarDesign} onClick={() => window.scrollTo(0, 0)}>
            <ConnectedSideBar
                userName={user?.name}
                logOut={logOutRequested}
                ListItems={listItems}
                userNameStyling={styles.userNameStyling}
                userProfileLink={'/profile'}
                iconStyling={styles.sidebarIcons}
                iconTextStyling={styles.sidebarText}
                // loggedUser={
                //     userName.length > 30
                //         ? userName.substring(0, 30) + '...'
                //         : userName.substring(0, 30) || 'Loading...'
                // }
                loggedUser={'Jean Jacques Gireau'}
                customWaterMarkStyling={styles.watermark}
            />
        </div>
    );

    return (
        <div className={styles.root}>
            <Sidebar />
            <div style={{ marginLeft: 150 }}>
                <Switch>
                    <Route exact path="/home" render={() => <ListingSearchScreen />} />
                    <Route exact path="/home/schedule" render={() => <ScheduleScreen />} />
                    <Route exact path="/listings" render={() => <ConnectedListingsScreen />} />
                    <Route
                        exact
                        path="/listings/config"
                        render={() => <ConnectedListingsConfigScreen />}
                    />
                    <Route exact path="/myShowings" render={() => <MyShowingsScreen />} />
                    <Route exact path="/clients" render={() => <ConnectedClientsScreen />} />
                    <Route exact path="/clients/addClient" render={() => <ConnectedAddClient />} />
                </Switch>
            </div>
            {/* <div className={styles.pageLoader}>
                <Loader />
                 <span>Loading your data</span>
                </div> */}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state),
    };
};

const mapDispatchToProps = {
    logOutRequested: userLogoutRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
