import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { connect, ConnectedProps } from 'react-redux';
import BackIcon from '../../../icons/backIcon.svg';
import {
    fetchAvailabilityRequested,
    fetchListingsRequested,
    setSelectedListing,
    clearAvailability,
    listingConfigRequested,
    clearSuccessMarker,
} from '../actions';
import {
    getAvailability,
    getAvailabilityLoading,
    getConfigSuccess,
    getListings,
    getListingsLoading,
    getSelectedListing,
    getSelectedListingClient,
} from '../selectors';
import { getUserProfile } from '../../auth/selectors';
import { DateTime } from 'luxon';
import {
    DEFAULT_INPUT_FIELDS,
    DEFAULT_ENUM_FIELDS,
    DEFAULT_PICKER_FIELDS,
    DROP_DOWN_FIELDS,
    APPLICATION_ID,
    LISTING_TEST_FIELDS,
    DEFAULT_PROFILE_FIELDS,
} from './constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { endTimesArr, timesArr } from '../../ListingSearch/ScheduleScreen/constants';
import { useHistory } from 'react-router';
import { fetchClientsRequested } from '../../clients/actions';
import { getClients } from '../../clients/selectors';

const mapStateToProps = (state: any) => ({
    listingAvailability: getAvailability(state),
    loading: getAvailabilityLoading(state) || getListingsLoading(state),
    user: getUserProfile(state),
    selectedListing: getSelectedListing(state),
    selectedListingClient: getSelectedListingClient(state),
    configSuccess: getConfigSuccess(state),
    clients: getClients(state),
});

const mapDispatchToProps = {
    fetchAvailabilityRequested: fetchAvailabilityRequested,
    setSelectedListing: setSelectedListing,
    clearAvailability: clearAvailability,
    listingConfigRequested: listingConfigRequested,
    clearSuccessMarker: clearSuccessMarker,
    fetchClientsRequested: fetchClientsRequested,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface ListingsConfigScreenProps extends ReduxProps {}

const dummyRule = {
    startDatetime: '2021-10-13T08:00:00+00:00',
    endDatetime: '2021-10-13T09:30:00+00:00',
    showListingId: '2d939ee8-2a18-4d5c-a84c-08d98d2fdfa1',
    dayOfWeek: {
        $numberInt: '3',
    },
    beginDate: '2021-10-13T08:00:00+00:00',
    numberOfWeeks: 3,
};

function ListingsConfigScreen(props: ListingsConfigScreenProps) {
    const {
        user,
        listingAvailability,
        fetchAvailabilityRequested,
        listingConfigRequested,
        selectedListing,
        setSelectedListing,
        clearAvailability,
        configSuccess,
        clearSuccessMarker,
        loading,
        fetchClientsRequested,
        selectedListingClient,
        clients,
    } = props;

    const history = useHistory();

    const isUpdate = !!selectedListing;

    useEffect(() => {
        if (configSuccess) {
            clearSuccessMarker();
            history.goBack();
        }
        if (isUpdate) {
            fetchAvailabilityRequested(selectedListing?.showListingId);
        }
        fetchClientsRequested();
        // remove selected listing when leaving screen
        return () => {
            setSelectedListing(null);
            clearAvailability();
        };
    }, [configSuccess]);

    const [listingMetaData, setListingMetaData] = useState<any>({
        applicationId: APPLICATION_ID,
        listAgentMlsId: user.agentMlsId,
        listAgentName: user.name,
        listAgentLicenseNumber: user.licenseNumber,
        listingAgentLicenseState: user.licenseState,
        // ...LISTING_TEST_FIELDS,
    });

    const [isNewRuleView, setNewRuleView] = useState<boolean>(false);
    const [rules, setRules] = useState<any>([]);
    const [newRuleConfig, setNewRuleConfig] = useState<any>({
        beginDate: null,
        startDatetime: null,
        endDatetime: null,
        numberOfWeeks: 0,
        reoccurring: false,
    });

    const [showableCalendars, setShowableCalendar] = useState<any>(['false', 'false']);

    const [attachedClientId, setAttachedClientId] = useState<string | null>(null);

    useEffect(() => {
        if (selectedListingClient) {
            setAttachedClientId(selectedListingClient);
        }
    }, [selectedListingClient]);

    // update meta data to include for current listing
    useEffect(() => {
        if (isUpdate && selectedListing) {
            setListingMetaData({
                ...selectedListing,
                universalPropertyId: selectedListing.upi,
                showingStatus: selectedListing.showingsAllowed,
            });
        }
    }, [selectedListing, isUpdate]);

    // update rules to include current listing availability
    useEffect(() => {
        var newRules: any = [];
        if (listingAvailability?.reoccurringRestrictions?.length) {
            listingAvailability.reoccurringRestrictions.map((r: any) => {
                newRules.push({
                    beginDate: new Date(r.beginDate),
                    startDatetime: new Date(r.startDatetime),
                    endDatetime: new Date(r.endDatetime),
                    numberOfWeeks: r.numberOfWeeks,
                    reoccurring: true,
                    id: r.id,
                    type: 'update',
                });
            });
        }
        if (listingAvailability?.restrictions?.length) {
            listingAvailability.restrictions.map((r: any) => {
                newRules.push({
                    beginDate: r.startDatetime,
                    startDatetime: r.startDatetime,
                    endDatetime: r.endDatetime,
                    numberOfWeeks: 0,
                    reoccurring: false,
                    id: r.id,
                    type: 'update',
                });
            });
        }
        if (newRules.length) {
            setRules(newRules);
        }
    }, [listingAvailability]);

    const [startTime, setStartTime] = useState('5:00am');
    const [endTime, setEndTime] = useState('5:15am');
    let startTimes = [...timesArr];
    let endTimes = [...endTimesArr];

    const MyContainer = ({ className, children }: any) => (
        <div className={styles.calendarStyle}>
            <div className={className}>
                <div className={styles.test}>{children}</div>
            </div>
        </div>
    );

    const createListingInput = (
        <div className={styles.inputContainer}>
            <div className={styles.inputHeader}>Fields</div>
            {DEFAULT_PROFILE_FIELDS.map((item: any, index) => {
                return (
                    <label className={styles.inputLabel}>
                        <span style={{ flex: 1 }}>{`${item.displayName}${
                            item.required ? '*' : ''
                        }`}</span>
                        {/* <input
                            placeholder={item.displayName}
                            onChange={(e) => {
                                var updatedFields = { ...listingMetaData };
                                updatedFields[item.field] = e.target.value;
                                setListingMetaData(updatedFields);
                            }}
                            value={listingMetaData[item.field]}
                            className={styles.inputStyle}
                            style={{ flex: 1 }}
                        /> */}
                        <div className={styles.profileField}>{listingMetaData[item.field]}</div>
                    </label>
                );
            })}
            {DEFAULT_INPUT_FIELDS.map((item: any, index) => {
                return (
                    <label className={styles.inputLabel}>
                        <span style={{ flex: 1 }}>{`${item.displayName}${
                            item.required ? '*' : ''
                        }`}</span>
                        <input
                            placeholder={item.displayName}
                            onChange={(e) => {
                                var updatedFields = { ...listingMetaData };
                                updatedFields[item.field] = e.target.value;
                                setListingMetaData(updatedFields);
                            }}
                            value={listingMetaData[item.field]}
                            className={styles.inputStyle}
                            style={{ flex: 1 }}
                        />
                    </label>
                );
            })}
            <br />
            {DEFAULT_ENUM_FIELDS.map((item: any, index) => {
                return (
                    <div className={styles.enumContainer}>
                        <div className={styles.enumTitle}>
                            {`${item.displayName}${item.required ? '*' : ''}`}
                        </div>
                        <div className={styles.enumSubContainer}>
                            {item.enum.map((e: any) => {
                                return (
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={listingMetaData[item.field] === e.field}
                                            onChange={() => {
                                                var updatedFields = { ...listingMetaData };
                                                updatedFields[item.field] = e.field;
                                                setListingMetaData(updatedFields);
                                            }}
                                            className={styles.checkBox}
                                        />
                                        <span>{e.displayName}</span>
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            <br />
            {DEFAULT_PICKER_FIELDS.map((item: any, index) => {
                var selected = listingMetaData[item.field];
                if (selected && typeof selected === 'string') {
                    selected = new Date(selected);
                }
                return (
                    <div className={styles.pickerContainer}>
                        <div className={styles.enumTitle}>
                            {' '}
                            {`${item.displayName}${item.required ? '*' : ''}`}{' '}
                        </div>
                        <div
                            className={styles.showableContainer}
                            onClick={() => {
                                var show = [...showableCalendars];
                                show[index] = 'true';
                                if (showableCalendars[index] === 'false') {
                                    setShowableCalendar(show);
                                }
                            }}
                        >
                            <DatePicker
                                selected={selected}
                                onChange={(date: any) => {
                                    var updatedFields = { ...listingMetaData };
                                    updatedFields[item.field] = new Date(date);
                                    setListingMetaData(updatedFields);

                                    // hide calendar view
                                    var show = [...showableCalendars];
                                    show[index] = 'false';
                                    setShowableCalendar(show);
                                }}
                                dateFormat={'EEEE MMMM d, yyyy'}
                                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={(e) => e.preventDefault()}
                                placeholderText={'Pick a Date'}
                                className={styles.calendarDisplayDate}
                                calendarContainer={MyContainer}
                                popperPlacement={'auto'}
                                open={showableCalendars[index] === 'true'}
                            />
                        </div>
                    </div>
                );
            })}
            {DROP_DOWN_FIELDS.map((item: any, index) => {
                return (
                    <div className={styles.dropDownContainer}>
                        <div className={styles.dropDownTitle}>
                            {`${item.displayName}${item.required ? '*' : ''}`}
                        </div>
                        <select
                            onChange={(e: any) => {
                                setAttachedClientId(e.target.value);
                            }}
                            value={attachedClientId || ''}
                            className={styles.dropDownPicker}
                        >
                            <option key={null} value={'None'}>
                                None
                            </option>
                            {clients?.length ? (
                                clients.map((client: any) => {
                                    return (
                                        <option
                                            key={JSON.stringify(client._id)}
                                            value={JSON.stringify(client._id)}
                                        >
                                            {client.name}
                                        </option>
                                    );
                                })
                            ) : (
                                <div>No Sellers Available</div>
                            )}
                        </select>
                    </div>
                );
            })}
        </div>
    );

    const currentRulesView = (
        <div
            className={styles.currentRulesContainer}
            style={{ marginTop: isNewRuleView ? 250 : 0 }}
        >
            {rules?.length &&
                rules.map((r: any, index: number) => {
                    const day = DateTime.fromJSDate(new Date(r.beginDate)).toFormat('DDD');
                    const weekDay = DateTime.fromJSDate(new Date(r.beginDate)).toFormat('cccc');
                    const start = DateTime.fromJSDate(new Date(r.startDatetime)).toFormat('t');
                    const end = DateTime.fromJSDate(new Date(r.endDatetime)).toFormat('t');

                    const removeButton = () => {
                        if (r.type === 'remove') {
                            return <div className={styles.removedTag}>Removed</div>;
                        } else {
                            return (
                                <button
                                    className={styles.removeButton}
                                    onClick={() => {
                                        var updateRules: any = [...rules];
                                        if (updateRules[index].type) {
                                            updateRules[index].type = 'remove';
                                        } else {
                                            updateRules.splice(index, 1);
                                        }
                                        setRules(updateRules);
                                    }}
                                >
                                    Remove
                                </button>
                            );
                        }
                    };

                    if (r.reoccurring || r.numberOfWeeks) {
                        return (
                            <div className={styles.currentRule}>
                                <div>{'Weekly Reoccurring Restriction'}</div>
                                <div style={{ marginLeft: 50, marginTop: 10 }}>
                                    <div>{`Start Date: ${day}`}</div>
                                    <div>{`Week Day: ${weekDay}`}</div>
                                    <div>{`Time: ${start} - ${end}`}</div>
                                    <div>{`Number of Weeks: ${r.numberOfWeeks}`}</div>
                                </div>
                                {removeButton()}
                            </div>
                        );
                    }
                    return (
                        <div className={styles.currentRule}>
                            <div>{'Individual Restriction'}</div>
                            <div style={{ marginLeft: 50, marginTop: 10 }}>
                                <div>{`Date: ${day}`}</div>
                                <div>{`Time: ${start} - ${end}`}</div>
                            </div>
                            {removeButton()}
                        </div>
                    );
                })}
        </div>
    );

    const timePicker = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: 300,
                marginLeft: 50,
            }}
        >
            <div className={styles.pickerContainer}>
                <span style={{ marginRight: 5 }}>Start Time</span>
                <select
                    className={styles.timePicker}
                    onChange={(e) => {
                        setStartTime(e.target.value);
                        const time = e.target.value;
                        var hours = time.substr(0, time.indexOf(':'));
                        const minutes = time.substr(time.indexOf(':') + 1, time.length - 4);
                        const meridian = time[time.indexOf('m') - 1];
                        if (meridian === 'p') hours += 12;
                        const startTime = new Date(newRuleConfig.beginDate);
                        startTime.setHours(parseInt(hours));
                        startTime.setMinutes(parseInt(minutes));
                        setNewRuleConfig({
                            ...newRuleConfig,
                            startDatetime: startTime,
                        });
                    }}
                >
                    {startTimes.map((time: string) => {
                        const notGoodStartTimes = [
                            '9:00pm',
                            '9:15pm',
                            '9:30pm',
                            '9:45pm',
                            '10:00pm',
                            '10:15pm',
                            '10:30pm',
                            '10:45pm',
                        ];
                        if (!notGoodStartTimes.includes(time))
                            return (
                                <option key={time} value={time}>
                                    {time}
                                </option>
                            );
                    })}
                </select>
            </div>
            <div className={styles.pickerContainer}>
                <span style={{ marginRight: 5 }}>End Time</span>
                <select
                    className={styles.timePicker}
                    value={endTime}
                    onChange={(e) => {
                        setEndTime(e.target.value);
                        const time = e.target.value;
                        var hours = time.substr(0, time.indexOf(':'));
                        const minutes = time.substr(time.indexOf(':') + 1, time.length - 4);
                        const meridian = time[time.indexOf('m') - 1];
                        if (meridian === 'p') hours += 12;
                        const endTime = new Date(newRuleConfig.beginDate);
                        endTime.setHours(parseInt(hours));
                        endTime.setMinutes(parseInt(minutes));
                        setNewRuleConfig({
                            ...newRuleConfig,
                            endDatetime: endTime,
                        });
                    }}
                >
                    {endTimes.map((time) => (
                        <option key={time} value={time}>
                            {time}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );

    const checkValidRule = () => {
        if (newRuleConfig.beginDate && newRuleConfig.startDatetime && newRuleConfig.endDatetime) {
            if (!newRuleConfig.reoccurring || newRuleConfig.numberOfWeeks) {
                return true;
            }
        }
        return false;
    };

    const rulesInput = (
        <div className={styles.inputContainer}>
            <div className={styles.inputHeader}>Restrictions</div>
            {isNewRuleView ? (
                <div>
                    <div className={styles.ruleSubHeader}>Add New Rule</div>
                    <div className={styles.newRuleContainer}>
                        <div className={styles.calendarContainer}>
                            <DatePicker
                                selected={newRuleConfig.beginDate}
                                onChange={(date: any) => {
                                    const start = new Date(date);
                                    start.setHours(5);
                                    const end = new Date(date);
                                    end.setHours(5);
                                    end.setMinutes(15);
                                    setNewRuleConfig({
                                        ...newRuleConfig,
                                        beginDate: date,
                                        startDatetime: start,
                                        endDatetime: end,
                                    });
                                }}
                                dateFormat={'EEEE MMMM d, yyyy'}
                                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={(e) => e.preventDefault()}
                                placeholderText={'Pick a Date'}
                                className={styles.calendarDisplayDate}
                                calendarContainer={MyContainer}
                                popperPlacement={'top-start'}
                                open={true}
                            />
                        </div>
                        {newRuleConfig.beginDate ? (
                            <div className={styles.timePickerContainer}>
                                {timePicker}
                                <div className={styles.checkBoxContainer}>
                                    <div style={{ marginTop: 3 }}>Reoccurring restriction?</div>
                                    <input
                                        type="checkbox"
                                        checked={newRuleConfig.reoccurring}
                                        onChange={() => {
                                            setNewRuleConfig({
                                                ...newRuleConfig,
                                                reoccurring: !newRuleConfig.reoccurring,
                                            });
                                        }}
                                        className={styles.checkBox}
                                    />
                                </div>
                                {newRuleConfig.reoccurring && (
                                    <div className={styles.checkBoxContainer}>
                                        <div style={{ marginTop: 3 }}>Number of Weeks</div>
                                        <input
                                            placeholder={'1'}
                                            onChange={(e) => {
                                                setNewRuleConfig({
                                                    ...newRuleConfig,
                                                    numberOfWeeks: e.target.value,
                                                });
                                            }}
                                            className={styles.checkBox}
                                        />
                                    </div>
                                )}
                                {checkValidRule() && (
                                    <div className={styles.checkBoxContainer}>
                                        <button
                                            className={styles.addRuleButton}
                                            onClick={() => {
                                                setRules([...rules, newRuleConfig]);
                                                setNewRuleConfig({
                                                    beginDate: null,
                                                    startDatetime: null,
                                                    endDatetime: null,
                                                    numberOfWeeks: 0,
                                                    reoccurring: false,
                                                });
                                                setNewRuleView(false);
                                            }}
                                        >
                                            Add Restriction
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div style={{ height: 150 }} />
                        )}
                    </div>
                </div>
            ) : (
                <button
                    className={styles.addRuleButton}
                    onClick={() => {
                        setNewRuleView(true);
                    }}
                >
                    Add Restriction
                </button>
            )}
            {rules?.length ? currentRulesView : null}
        </div>
    );

    if (loading) {
        return (
            <div className={styles.root}>
                <div className={styles.pageHeader}>Listing Config Loading...</div>
            </div>
        );
    }

    const checkAllFields = () => {
        var valid: boolean = true;
        var missingFields: any = '';
        const allFields = [
            ...DEFAULT_INPUT_FIELDS,
            ...DEFAULT_ENUM_FIELDS,
            ...DEFAULT_PICKER_FIELDS,
            ...DROP_DOWN_FIELDS,
        ];
        allFields.map((i) => {
            if (i.required && !listingMetaData[i.field]) {
                valid = false;
                missingFields += `${i.displayName}, `;
            }
        });
        return { valid, missingFields };
    };

    return (
        <div className={styles.root}>
            <div className={styles.pageHeader} onClick={() => history.goBack()}>
                <img src={BackIcon} className={styles.backIcon} />{' '}
                {isUpdate ? 'Edit Listing' : 'Create Listing'}
            </div>
            <div className={styles.configContainer}>
                {createListingInput}
                {rulesInput}
            </div>
            <div className={styles.submitContainer}>
                <button
                    className={styles.submitButton}
                    onClick={() => {
                        listingConfigRequested(
                            listingMetaData,
                            rules,
                            selectedListing?.showListingId || null,
                            attachedClientId || null,
                        );
                    }}
                    disabled={!checkAllFields().valid}
                    style={{
                        backgroundColor: checkAllFields().valid ? '#986df4' : 'lightgrey',
                    }}
                >
                    SUBMIT
                </button>
            </div>
            {checkAllFields().missingFields ? (
                <div className={styles.missingFieldsContainer}>
                    **Missing Required Fields**: {checkAllFields().missingFields}
                </div>
            ) : null}
        </div>
    );
}

export const ConnectedListingsConfigScreen = connector(ListingsConfigScreen);
