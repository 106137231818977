export enum STATUS {
    Requested = 'Requested',
    Succeeded = 'Succeeded',
    Failed = 'Failed',
    Refresh = 'Refresh',
}

export enum UserTypes {
    Agent = 'Agent',
    Inspector = 'inspector',
    Maintenance = 'maintenance',
    Appraiser = 'appraiser',
}

/**
 * Takes the phone number format stored in stitch and returns the number in
 * the format `+1 (012) 345-6789` or w/o international code if none is given.
 * @param {string} phoneNumber in the format `+10123456789` or w/o intl code
 * @param {boolean} includeDialingCode - Whether or not to add the country code
 * @returns {string}
 */
export function formatPhoneNumberForDisplay(phoneNumber: string, includeDialingCode = false) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match && match.length > 4) {
        const intlCode = match[1] ? '+1 ' : '';
        return includeDialingCode
            ? [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
            : ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber;
}
/**
 * Remove dashes, spaces, and other special characters from phone number.
 * Optionally adds International dialing code
 * @param {string|number} phoneNumber
 * @param {string} dialingCode - defaults to U.S.
 * @param {boolean} includeDialingCode - Whether or not to add the country code
 * @returns {string}
 */
export function scrubAndFormatPhoneNumber(
    phoneNumber: string,
    dialingCode = '1',
    includeDialingCode = true,
) {
    const scrubbedPhoneNumber = `${phoneNumber}`.replace(/[^\d]/g, '');
    return includeDialingCode ? `+${dialingCode}${scrubbedPhoneNumber}` : `${scrubbedPhoneNumber}`;
}

export const statesArr = [
    'Select State',
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
];

export const transformAppsAndRequests = (appsAndRequests: any) => {
    return {
        requests: appsAndRequests[0]?.requests,
        appointments: appsAndRequests[1]?.appointments,
    };
};
