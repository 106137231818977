import { STATUS } from '../../utils/common/constants';
import { MY_SHOWINGS_ACTION } from './actions';

export const initialState = {
    myShowings: {},
    showingActionComplete: false,
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case MY_SHOWINGS_ACTION.FetchMyShowings: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                        showingActionComplete: false,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loading: false,
                        myShowings: action.myShowings,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loading: false,
                        errors: action.errors,
                    };
                }
                default:
                    return state;
            }
        }
        case MY_SHOWINGS_ACTION.ConfirmShowing: {
            switch (status) {
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        showingActionComplete: true,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        showingActionComplete: true,
                    };
                }
                default:
                    return state;
            }
        }
        case MY_SHOWINGS_ACTION.DenyShowing: {
            switch (status) {
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        showingActionComplete: true,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        showingActionComplete: true,
                    };
                }
                default:
                    return state;
            }
        }
        default:
            return state;
    }
}
