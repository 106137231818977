import { STATUS } from '../../utils/common/constants';
import { SEARCH_ACTION } from './actions';

export const initialState = {
    searchParams: {},
    listings: [],
    updating: false,
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case SEARCH_ACTION.SearchListings: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        searchParams: action.searchParams,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        listings: action.listings,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        errors: action.errors,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.FetchListingAvailability: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        showListingId: action.showListingId,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        availability: action.availability,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        errors: action.errors,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.CreateShowing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loading: false,
                        createdShowing: action.createdShowing,
                        updating: false,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loading: false,
                        errors: action.errors,
                        updating: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.ClearCreatedShowing: {
            return {
                ...state,
                createdShowing: null,
            };
        }
        case SEARCH_ACTION.UpdateSelectedListing: {
            return {
                ...state,
                selectedListing: action.listing,
            };
        }
        case SEARCH_ACTION.ClearSelectedListing: {
            return {
                ...state,
                selectedListing: null,
            };
        }
        case SEARCH_ACTION.CancelShowing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                default:
                    return state;
            }
        }
        case SEARCH_ACTION.SetUpdating: {
            return {
                ...state,
                updating: true,
                passedRequestIds: action.passedRequestIds,
            };
        }
        default:
            return state;
    }
}
