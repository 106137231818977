import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { connect, ConnectedProps } from 'react-redux';
import {
    confirmShowingRequested,
    denyShowingRequested,
    fetchMyShowingsRequested,
} from '../actions';
import { getMyShowings, getShowingActionComplete } from '../selectors';
import ShowingCard from '../ShowingCard';
import { getUser } from '../../auth/selectors';
import {
    cancelShowingRequested,
    fetchListingAvailabilityRequested,
    setUpdateShowing,
    updateSelectedListing,
} from '../../ListingSearch/actions';
import { useHistory } from 'react-router';
import { getLoading } from '../selectors';
import ShowingCardList from '../ShowingCardListSide';

const mapStateToProps = (state: any) => ({
    myShowings: getMyShowings(state),
    user: getUser(state),
    loading: getLoading(state),
    showingActionComplete: getShowingActionComplete(state),
});

const mapDispatchToProps = {
    fetchMyShowingsRequested: fetchMyShowingsRequested,
    setUpdateShowing: setUpdateShowing,
    updateSelectedListing: updateSelectedListing,
    cancelShowingRequested: cancelShowingRequested,
    fetchListingAvailabilityRequested: fetchListingAvailabilityRequested,
    denyShowing: denyShowingRequested,
    confirmShowing: confirmShowingRequested,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps {}

const MyShowingsScreen = ({
    fetchMyShowingsRequested,
    myShowings,
    user,
    setUpdateShowing,
    updateSelectedListing,
    cancelShowingRequested,
    loading,
    fetchListingAvailabilityRequested,
    denyShowing,
    confirmShowing,
    showingActionComplete,
}: Props) => {
    const [selectedType, setSelectedType] = useState<string>('buySide');
    const [cancelRequest, setCancelRequest] = useState<boolean>(false);
    const history = useHistory();

    const newIds = user?.associatedListings?.map((id: any) => {
        return id?.showListingId;
    });

    useEffect(() => {
        if (selectedType === 'buySide') {
            fetchMyShowingsRequested({
                pageId: 0,
                pageSize: 50,
                filterField: 'buyingAgentID',
                filterOperator: 'Equal',
                filterValues: [user?.agentMlsId],
                orderByField: 'startDateTime',
                orderByDirection: 'Asc',
            });
        } else if (selectedType === 'listSide') {
            fetchMyShowingsRequested({
                pageId: 0,
                pageSize: 50,
                filterField: 'showListingId',
                filterOperator: 'Equal',
                filterValues: newIds,
                orderByField: 'startDateTime',
                orderByDirection: 'Asc',
            });
        }
    }, [selectedType, cancelRequest, showingActionComplete]);

    return (
        <div style={{ paddingTop: 30 }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignSelf: 'center',
                }}
            >
                <span className={styles.pageTitle}>Showings</span>
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
                    {user?.userType === 'agent' && (
                        <>
                            <span
                                className={
                                    selectedType === 'buySide'
                                        ? styles.selectedOption
                                        : styles.option
                                }
                                onClick={() => setSelectedType('buySide')}
                            >
                                Buy Side
                            </span>
                            <span
                                className={
                                    selectedType === 'listSide'
                                        ? styles.selectedOption
                                        : styles.option
                                }
                                onClick={() => setSelectedType('listSide')}
                            >
                                List Side
                            </span>
                        </>
                    )}
                </div>
            </div>
            <div>
                {loading ? (
                    <div>Loading Your Data...</div>
                ) : myShowings?.requests?.length ? (
                    myShowings?.requests?.map((showing: any) => {
                        return selectedType === 'buySide' ? (
                            <ShowingCard
                                showing={showing}
                                onClickUpdate={(isNotReschedule: boolean) => {
                                    if (isNotReschedule) {
                                        setUpdateShowing({ requestId: showing?.id });
                                    }
                                    fetchListingAvailabilityRequested(showing?.showListingId);
                                    updateSelectedListing(showing);
                                    history.push('/home/schedule');
                                }}
                                onClickCancel={() => {
                                    cancelShowingRequested(showing, {
                                        pageId: 0,
                                        pageSize: 50,
                                        filterField: 'buyingAgentID',
                                        filterOperator: 'Equal',
                                        filterValues: [user?.agentMlsId],
                                        orderByField: 'startDateTime',
                                        orderByDirection: 'Asc',
                                    });
                                    setCancelRequest(!cancelRequest);
                                }}
                            />
                        ) : (
                            <ShowingCardList
                                showing={showing}
                                denyShowing={() => denyShowing(showing?.id)}
                                confirmShowing={() => confirmShowing(showing?.id)}
                            />
                        );
                    })
                ) : (
                    <div className={styles.noShowingsText}>No Showings Here</div>
                )}
            </div>
        </div>
    );
};
export default connector(MyShowingsScreen);
