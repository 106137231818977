import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { connect, ConnectedProps } from 'react-redux';
import { addClientRequested, clearAddClientSuccess, fetchClientsRequested } from '../actions';
import { getAddClientSuccess, getAddingClient, getClients, getClientsLoading } from '../selectors';
import { getUserProfile } from '../../auth/selectors';
import { DateTime } from 'luxon';
import CheckIcon from '../../../icons/checkmark.svg';
import XIcon from '../../../icons/xIcon.svg';
import BackIcon from '../../../icons/backIcon.svg';
import { useHistory } from 'react-router';

const mapStateToProps = (state: any) => ({
    loading: getAddingClient(state),
    addClientSuccess: getAddClientSuccess(state),
});

const mapDispatchToProps = {
    addClientRequested: addClientRequested,
    clearAddClientSuccess: clearAddClientSuccess,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface AddClientProps extends ReduxProps {}

function AddClient(props: AddClientProps) {
    const { addClientRequested, loading, addClientSuccess, clearAddClientSuccess } = props;

    const [clientInfo, setClientInfo] = useState<any>({});
    const history = useHistory();

    const disabled = !clientInfo.phone || !clientInfo.email || !clientInfo.name;

    const statusMessage = addClientSuccess
        ? 'Successfully added your client.'
        : 'Could not add client. Please Try again.';
    const statusIcon = addClientSuccess ? CheckIcon : XIcon;

    useEffect(() => {
        if (addClientSuccess && !loading) {
            setTimeout(() => {
                clearAddClientSuccess();
                history.push('/clients');
            }, 2500);
        }
    }, [addClientSuccess]);

    return (
        <div className={styles.root}>
            <div className={styles.pageHeader}>Add Client</div>
            <div
                className={styles.backContainer}
                onClick={() => {
                    if (!loading && history.location.pathname === '/clients/addClient') {
                        clearAddClientSuccess();
                        history.push('/clients');
                    }
                }}
            >
                <img src={BackIcon} className={styles.backIcon} />
                <span className={styles.success}> Back </span>
            </div>
            {loading ? (
                <div>Loading...</div>
            ) : addClientSuccess !== null ? (
                <div
                    style={{
                        flex: 1,
                        alignSelf: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                        maxWidth: '400px',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                    }}
                >
                    <img src={statusIcon} />
                    <p className={styles.success}>{statusMessage}</p>
                </div>
            ) : (
                <>
                    <div
                        style={{
                            flex: 1,
                            alignSelf: 'center',
                            flexDirection: 'column',
                            textAlign: 'center',
                            maxWidth: '400px',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        <div>Please enter your client's info:</div>
                        <input
                            placeholder="Full Name"
                            className={styles.inputStyle}
                            onChange={(e) => setClientInfo({ ...clientInfo, name: e.target.value })}
                        />
                        <input
                            placeholder="Phone Number"
                            className={styles.inputStyle}
                            onChange={(e) => {
                                setClientInfo({ ...clientInfo, phone: '+1' + e.target.value });
                                if (!e.target.value.length) {
                                    setClientInfo({ ...clientInfo, phone: undefined });
                                }
                            }}
                            maxLength={10}
                        />
                        <input
                            placeholder="Email"
                            className={styles.inputStyle}
                            onChange={(e) =>
                                setClientInfo({ ...clientInfo, email: e.target.value })
                            }
                        />
                    </div>
                    <div className={styles.buttonContainer}>
                        <button
                            disabled={disabled}
                            onClick={() => addClientRequested(clientInfo)}
                            className={styles.button}
                            style={disabled ? { backgroundColor: 'lightGray' } : {}}
                        >
                            Add
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export const ConnectedAddClient = connector(AddClient);
