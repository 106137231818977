export const CLIENTS_PREFIX = 'clients/';

export const FETCH_CLIENTS_REQUESTED = CLIENTS_PREFIX + 'FETCH_CLIENTS_REQUESTED';
export const FETCH_CLIENTS_SUCCEEDED = CLIENTS_PREFIX + 'FETCH_CLIENTS_SUCCEEDED';
export const FETCH_CLIENTS_FAILED = CLIENTS_PREFIX + 'FETCH_CLIENTS_FAILED';

export const fetchClientsRequested = () => {
    return {
        type: FETCH_CLIENTS_REQUESTED,
    } as const;
};
export const fetchClientsSucceeded = (clients: any[]) => {
    return {
        type: FETCH_CLIENTS_SUCCEEDED,
        clients,
    } as const;
};
export const fetchClientsFailed = (errors: any) => {
    return {
        type: FETCH_CLIENTS_FAILED,
        errors,
    } as const;
};

export const ADD_CLIENT_REQUESTED = CLIENTS_PREFIX + 'ADD_CLIENT_REQUESTED';
export const ADD_CLIENT_SUCCEEDED = CLIENTS_PREFIX + 'ADD_CLIENT_SUCCEEDED';
export const ADD_CLIENT_FAILED = CLIENTS_PREFIX + 'ADD_CLIENT_FAILED';

export const addClientRequested = (clientInfo: any) => {
    return {
        type: ADD_CLIENT_REQUESTED,
        clientInfo,
    } as const;
};
export const addClientSucceeded = () => {
    return {
        type: ADD_CLIENT_SUCCEEDED,
    } as const;
};
export const addClientFailed = (errors: any) => {
    return {
        type: ADD_CLIENT_FAILED,
        errors,
    } as const;
};

export const CLEAR_ADD_CLIENT_SUCCESS = CLIENTS_PREFIX + 'CLEAR_ADD_CLIENT_SUCCESS';
export const clearAddClientSuccess = () => {
    return {
        type: CLEAR_ADD_CLIENT_SUCCESS,
    } as const;
};

export const DELETE_CLIENT_REQUESTED = CLIENTS_PREFIX + 'DELETE_CLIENT_REQUESTED';
export const DELETE_CLIENT_SUCCEEDED = CLIENTS_PREFIX + 'DELETE_CLIENT_SUCCEEDED';
export const DELETE_CLIENT_FAILED = CLIENTS_PREFIX + 'DELETE_CLIENT_FAILED';

export const deleteClientRequested = (clientId: any) => {
    return {
        type: DELETE_CLIENT_REQUESTED,
        clientId,
    } as const;
};
export const deleteClientSucceeded = () => {
    return {
        type: DELETE_CLIENT_SUCCEEDED,
    } as const;
};
export const deleteClientFailed = (errors: any) => {
    return {
        type: DELETE_CLIENT_FAILED,
        errors,
    } as const;
};
