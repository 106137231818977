import { all, call, put, select, takeLatest } from '@redux-saga/core/effects';
import { findMyConsumers, getCollection, getUserRecord } from '../../store/api/sagas';
import { getRealmUserId } from '../auth/selectors';
import * as CLIENT_ACTIONS from './actions';

/**
 * Fetch all clients associated with the given user
 */
export function* fetchClients({}): Generator<any, any, any> {
    try {
        const realmUserId = yield select(getRealmUserId);
        const user = yield call(getUserRecord, realmUserId);
        if (user?._id) {
            const clients = yield call(findMyConsumers, user?._id);
            yield put(CLIENT_ACTIONS.fetchClientsSucceeded(clients));
        }
    } catch (err) {
        yield put(CLIENT_ACTIONS.fetchClientsFailed(err));
    }
}

/**
 * Fetch all clients associated with the given user
 */
export function* addClient({ clientInfo }: any): Generator<any, any, any> {
    try {
        const realmUserId = yield select(getRealmUserId);
        const user = yield call(getUserRecord, realmUserId);
        if (user?._id) {
            const consumers = yield getCollection('consumers');
            const lastModifiedDate = new Date();
            const clients = yield consumers.insertOne({
                agentId: user?._id,
                ...clientInfo,
                lastModified: lastModifiedDate,
            });
            yield put(CLIENT_ACTIONS.addClientSucceeded());
            yield put(CLIENT_ACTIONS.fetchClientsRequested());
        }
    } catch (err) {
        yield put(CLIENT_ACTIONS.addClientFailed(err));
    }
}

/**
 * Fetch all clients associated with the given user
 */
export function* deleteClient({ clientId }: any): Generator<any, any, any> {
    try {
        if (clientId) {
            const consumers = yield getCollection('consumers');
            const deleted = yield consumers.updateOne(
                {
                    _id: clientId,
                },
                { $unset: { agentId: '' } },
            );
            if (deleted.modifiedCount) {
                yield put(CLIENT_ACTIONS.deleteClientSucceeded());
            }
            yield put(CLIENT_ACTIONS.fetchClientsRequested());
        }
    } catch (err) {
        yield put(CLIENT_ACTIONS.deleteClientFailed(err));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(CLIENT_ACTIONS.FETCH_CLIENTS_REQUESTED, fetchClients),
        takeLatest(CLIENT_ACTIONS.ADD_CLIENT_REQUESTED, addClient),
        takeLatest(CLIENT_ACTIONS.DELETE_CLIENT_REQUESTED, deleteClient),
    ]);
}
