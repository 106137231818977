import { all, call } from 'redux-saga/effects';
import authSaga from '../domains/auth/sagas';
import clientsSaga from '../domains/clients/sagas';
import initSaga from '../init/saga';
import searchSaga from '../domains/ListingSearch/sagas';
import listingsSaga from '../domains/listings/saga';
import myShowingsSaga from '../domains/myShowings/sagas';

export default function* rootSaga(): Generator<any, any, any> {
    yield all([
        call(authSaga),
        call(initSaga),
        call(searchSaga),
        call(listingsSaga),
        call(myShowingsSaga),
        call(clientsSaga),
    ]);
}
