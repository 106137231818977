import { createSelector } from 'reselect';

export const getSearchState = (state: any) => state.search || {};

export const getSearchedListings = createSelector(getSearchState, (search) => search.listings);

export const getSelectedListing = createSelector(
    getSearchState,
    (search) => search.selectedListing,
);

export const getListingAvailability = createSelector(
    getSearchState,
    (search) => search.availability,
);

export const getCreatedShowing = createSelector(getSearchState, (search) => search.createdShowing);

export const getLoading = createSelector(getSearchState, (search) => search.loading);

export const getUpdating = createSelector(getSearchState, (search) => search.updating);

export const getPassedRequestIds = createSelector(
    getSearchState,
    (search) => search.passedRequestIds,
);
