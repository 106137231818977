import { AUTH_ACTION } from './actions';
import { STATUS } from '../../utils/common/constants';

export const initialState = {
    // Whether the app is in the process of booting up or not
    initializing: false,

    isLoggedIn: false,

    loginError: null,
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case AUTH_ACTION.UserLogin: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loginError: null,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loginError: null,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loginError: error,
                    };
                }

                default:
                    return state;
            }
        }
        case AUTH_ACTION.Initialize: {
            switch (status) {
                case STATUS.Requested: {
                    const { initializing } = action;
                    return {
                        ...state,
                        initializing,
                    };
                }

                case STATUS.Succeeded: {
                    const { isLoggedIn } = action;
                    return {
                        ...state,
                        isLoggedIn,
                        initializing: false,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                        initializing: false,
                    };
                }

                default:
                    return state;
            }
        }
        case AUTH_ACTION.Create: {
            switch (status) {
                case STATUS.Requested: {
                    const { user } = action;
                    return {
                        ...state,
                        user,
                        loading: true,
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        isLoggedIn: true,
                        loading: false,
                    };
                }

                case STATUS.Failed: {
                    return {
                        ...state,
                        loading: false,
                        loginError: action.errors,
                    };
                }
                default:
                    return state;
            }
        }
        case AUTH_ACTION.SetClient: {
            const { client } = action;
            return {
                ...state,
                client,
                isLoggedIn: true,
            };
        }
        case AUTH_ACTION.SetRealmUserId: {
            const { realmUserId } = action;
            return {
                ...state,
                realmUserId,
            };
        }
        case AUTH_ACTION.SetUserRecord: {
            const { userRecord } = action;
            return {
                ...state,
                user: userRecord,
            };
        }
        default:
            return state;
    }
}
