import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { callStitchFunction, findShowingConsumer } from '../../store/api/sagas';
import { STATUS, transformAppsAndRequests } from '../../utils/common/constants';
import * as Actions from './actions';

export function* fetchMyShowings({
    params,
}: ReturnType<typeof Actions.fetchMyShowingsRequested>): Generator<any, any, any> {
    try {
        const appointments = yield call(callStitchFunction, 'getAppointments', params);
        if (appointments?.length) {
            const formattedAppointments = transformAppsAndRequests(appointments);

            const requests = yield all(
                formattedAppointments?.requests?.map(async (request: any) => {
                    const listing = await callStitchFunction('getListing', request?.showListingId);
                    if (params.filterField === 'buyingAgentID') {
                        const consumer = await findShowingConsumer(request.id);
                        if (consumer) return { ...listing?.results[0], consumer, ...request };
                    }
                    return { ...listing?.results[0], ...request };
                }),
            );

            yield put(
                Actions.fetchMyShowingsSucceeded({
                    requests,
                    appointments: formattedAppointments?.appointments,
                }),
            );
        }
    } catch (error) {
        yield put(Actions.fetchMyShowingsFailed(error));
    }
}

export function* confirmShowing({
    requestId,
}: ReturnType<typeof Actions.confirmShowingRequested>): Generator<any, any, any> {
    try {
        const confirm = yield call(callStitchFunction, 'confirmRequest', { requestId });
        if (confirm) {
            console.log('d', confirm);
            yield put(Actions.confirmShowingSucceeded());
        }
    } catch (error) {
        yield put(Actions.confirmShowingFailed(error));
    }
}

export function* denyShowing({
    requestId,
}: ReturnType<typeof Actions.denyShowingRequested>): Generator<any, any, any> {
    try {
        const deny = yield call(callStitchFunction, 'denyRequest', { requestId });
        if (deny) {
            console.log('d', deny);
            yield put(Actions.denyShowingSucceeded());
        }
    } catch (error) {
        yield put(Actions.denyShowingFailed(error));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === Actions.MY_SHOWINGS_ACTION.FetchMyShowings &&
                action.status === STATUS.Requested,
            fetchMyShowings,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.MY_SHOWINGS_ACTION.ConfirmShowing &&
                action.status === STATUS.Requested,
            confirmShowing,
        ),
        takeLatest(
            (action: any) =>
                action.type === Actions.MY_SHOWINGS_ACTION.DenyShowing &&
                action.status === STATUS.Requested,
            denyShowing,
        ),
    ]);
}
