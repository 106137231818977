import { createSelector } from 'reselect';

export const getListingsState = (state: any) => state.listings || {};

export const getListingsLoading = createSelector(getListingsState, (listings) => listings.loading);

export const getListings = createSelector(getListingsState, (listings) => listings.listings);

export const getAvailability = createSelector(
    getListingsState,
    (listings) => listings.availability,
);
export const getAvailabilityLoading = createSelector(
    getListingsState,
    (listings) => listings.availabilityLoading,
);

export const getSelectedListing = createSelector(
    getListingsState,
    (listings) => listings.selectedListing?.listing,
);

export const getSelectedListingClient = createSelector(
    getListingsState,
    (listings) => listings.selectedListing?.clientId,
);

export const getConfigSuccess = createSelector(
    getListingsState,
    (listings) => listings.configSuccess,
);
