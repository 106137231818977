import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { connect, ConnectedProps } from 'react-redux';
import { statesArr } from '../../../utils/common/constants';
import { getSearchedListings } from '../selectors';
import {
    fetchListingAvailabilityRequested,
    textSearchListingsRequested,
    updateSelectedListing,
} from '../actions';
import ListingCard from '../ListingCard';
import { useHistory } from 'react-router';

const mapStateToProps = (state: any) => ({
    listings: getSearchedListings(state),
});

const mapDispatchToProps = {
    textSearchListingsRequested: textSearchListingsRequested,
    updateSelectedListing: updateSelectedListing,
    fetchListingAvailabilityRequested: fetchListingAvailabilityRequested,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps {
    styleSheet: any;
    navigation: any;
}

const ListingSearchScreen = ({
    textSearchListingsRequested,
    listings,
    updateSelectedListing,
    fetchListingAvailabilityRequested,
}: Props) => {
    const [searchParams, setSearchParams] = useState<any>({});

    const history = useHistory();

    useEffect(() => {
        textSearchListingsRequested(searchParams);
    }, [searchParams]);
    return (
        <div className={styles.root}>
            <span className={styles.pageTitle}>Search Listings</span>
            <div className={styles.fieldContainers}>
                <select
                    name="State"
                    defaultValue={'Select State'}
                    onChange={(e) => setSearchParams({ ...searchParams, state: e.target.value })}
                    className={styles.options}
                >
                    {statesArr.map((state) => (
                        <option key={state}>{state}</option>
                    ))}
                </select>
                <input
                    placeholder={'Address'}
                    onChange={(e) => setSearchParams({ ...searchParams, address: e.target.value })}
                    className={styles.input}
                />
                <input
                    placeholder={'City'}
                    onChange={(e) => setSearchParams({ ...searchParams, city: e.target.value })}
                    className={styles.input}
                />
                <input
                    placeholder={'Zip Code'}
                    onChange={(e) => setSearchParams({ ...searchParams, zipCode: e.target.value })}
                    className={styles.input}
                />
            </div>
            {listings?.map((listing: any) => {
                return (
                    <>
                        <ListingCard
                            listing={listing}
                            onClickSchedule={() => {
                                updateSelectedListing(listing);
                                fetchListingAvailabilityRequested(listing?.showListingId);
                                history.push('/home/schedule');
                            }}
                        />
                        <div className={styles.seperator} />
                    </>
                );
            })}
        </div>
    );
};
export default connector(ListingSearchScreen);
