import React, { useState, useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
    getCreatedShowing,
    getListingAvailability,
    getLoading,
    getPassedRequestIds,
    getSelectedListing,
    getUpdating,
} from '../selectors';
import { timesArr, endTimesArr } from './constants';
import styles from './index.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './custom.css';
import { DateTime, Interval } from 'luxon';
import { clearCreatedShowing, createShowingRequested } from '../actions';
import { getUser } from '../../auth/selectors';
import { useHistory } from 'react-router';
import CheckIcon from '../../../icons/checkmark.svg';
import { UserTypes } from '../../../utils/common/constants';
import { getClients } from '../../clients/selectors';
import { fetchClientsRequested } from '../../clients/actions';

const mapStateToProps = (state: any) => ({
    selectedListing: getSelectedListing(state),
    availability: getListingAvailability(state),
    user: getUser(state),
    createdShowing: getCreatedShowing(state),
    loading: getLoading(state),
    updating: getUpdating(state),
    clients: getClients(state),
    passedRequestIds: getPassedRequestIds(state),
});

const mapDispatchToProps = {
    createShowingRequested: createShowingRequested,
    clearCreatedShowing: clearCreatedShowing,
    fetchClients: fetchClientsRequested,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps {}

const ScheduleScreen = ({
    selectedListing,
    availability,
    createShowingRequested,
    user,
    createdShowing,
    clearCreatedShowing,
    loading,
    updating,
    clients,
    fetchClients,
    passedRequestIds,
}: Props) => {
    const history = useHistory();
    let startTimes = [...timesArr];
    let endTimes = [...endTimesArr];
    const [endTimesToUse, setEndTimesToUse] = useState<any>(endTimes);
    const [startTime, setStartTime] = useState('5:00am');
    const [endTime, setEndTime] = useState('5:15am');
    const [selectedStartTime, setSelectedStartTime] = useState<any>(new Date());
    const [selectedEndTime, setSelectedEndTime] = useState<any>(new Date());
    const [overlap, setOverlap] = useState<boolean>(false);
    const [requestNotes, setRequestNotes] = useState<string | null>(null);
    const [appointmentMethod, setAppointmentMethod] = useState<string>('InPersonOnly');
    const [buyerClient, setBuyerClient] = useState<string | null>('None');

    const sanitizedBlockedTimes = availability?.blockedTimes.map((bt: any) => {
        const { startDatetime, endDatetime } = bt;
        const sdt = DateTime.fromJSDate(startDatetime);
        const edt = DateTime.fromJSDate(endDatetime);
        const now = DateTime.local();
        const newSdt = sdt.set({ year: now.year, month: now.month, day: now.day });
        const newEdt = edt.set({ year: now.year, month: now.month, day: now.day });

        return {
            startDatetime: newSdt,
            endDatetime: newEdt,
        };
    });

    let startHours: any;
    let startMinutes: any;
    if (startTime.includes('pm')) {
        startTime.split(':');
        startHours = Number(startTime.split(':')[0]) + 12;
        startMinutes = Number(startTime.split(':')[1].split('pm')[0]);
    } else {
        startTime.split(':');
        startHours = Number(startTime.split(':')[0]);
        startMinutes = Number(startTime.split(':')[1].split('am')[0]);
    }

    let endHours: any;
    let endMinutes: any;
    if (endTime?.includes('pm')) {
        endTime?.split(':');
        endHours = Number(endTime?.split(':')[0]) + 12;
        endMinutes = Number(endTime?.split(':')[1].split('pm')[0]);
    } else {
        endTime?.split(':');
        endHours = Number(endTime?.split(':')[0]);
        endMinutes = Number(endTime?.split(':')[1].split('am')[0]);
    }

    // Below we calculate how many days the listing has left on the market, so we can
    // block out the days it will not be on the market
    const date1 = new Date(availability?.marketDuration?.showableEndDate);
    const date2 = new Date();
    const daysLeftOnMarket = (date1.getTime() - date2.getTime()) / (1000 * 3600 * 24);

    // The logic below makes it so the user cannot schedule a showing that starts and ends at the same time,
    // and cannot schedule a showing that ends before it starts
    useEffect(() => {
        let rerun = false;
        const currentFirstEndTimeIndex = startTimes.indexOf(endTimesToUse[0]);
        const currentSelectedEndTimeIndex = startTimes.indexOf(endTime);
        const currentSelectedStartTimeIndex = startTimes.indexOf(startTime);
        const currentSelectedStartTimeEndTimesIndex = endTimesToUse.indexOf(startTime);
        const newIndex = currentSelectedStartTimeEndTimesIndex + 1;
        if (currentSelectedEndTimeIndex - currentSelectedStartTimeIndex > 8) {
            rerun = true;
            const currentStartTimeIndexOfOldEndTimes = endTimes.indexOf(startTime);
            const newEndTimesToUse = endTimes.slice(currentStartTimeIndexOfOldEndTimes, -1);
            const newEndTime = newEndTimesToUse[8];
            setEndTimesToUse(newEndTimesToUse);
            setEndTime(newEndTime);
        } else {
            if (currentSelectedEndTimeIndex <= currentSelectedStartTimeIndex) {
                setEndTime(endTimesToUse[newIndex]);
                rerun = true;
            }
            if (currentFirstEndTimeIndex <= currentSelectedStartTimeIndex) {
                setEndTimesToUse(endTimesToUse.slice(newIndex, -1));
            }
        }
        if (!rerun) {
            const newSelectedStartTime = new Date(selectedStartTime);
            newSelectedStartTime.setHours(startHours, startMinutes, 0, 0);
            setSelectedStartTime(newSelectedStartTime);
            const newSelectedEndTime = new Date(selectedEndTime);
            newSelectedEndTime.setHours(endHours, endMinutes, 0, 0);
            setSelectedEndTime(newSelectedEndTime);
        } else {
        }
    }, [startTime, endTime]);

    useEffect(() => {
        if (sanitizedBlockedTimes?.length) {
            const selectedStartTimeDateTime = DateTime.fromJSDate(selectedStartTime);
            const selectedEndTimeDateTime = DateTime.fromJSDate(selectedEndTime);
            const selectedInterval = Interval.fromDateTimes(
                selectedStartTimeDateTime,
                selectedEndTimeDateTime,
            );
            setOverlap(false);
            sanitizedBlockedTimes.map((blockedTime: any) => {
                const { startDatetime, endDatetime } = blockedTime;
                const blockedInterval = Interval.fromDateTimes(startDatetime, endDatetime);
                if (blockedInterval.overlaps(selectedInterval)) {
                    setOverlap(true);
                }
            });
        }
    }, [selectedStartTime, selectedEndTime, availability]);

    useEffect(() => {
        if (createdShowing) {
            setTimeout(() => {
                if (history.location.pathname === '/home/schedule') {
                    history.push('/home');
                }
                clearCreatedShowing();
            }, 2500);
        }
    }, [createdShowing]);

    useEffect(() => {
        fetchClients();
    }, []);

    const MyContainer = ({ className, children }: any) => (
        <div className={styles.calendarStyle}>
            <div className={className}>
                <div className={styles.test}>{children}</div>
            </div>
        </div>
    );
    const addDays = (date: Date, days: number) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    const getAvailableDates = () => {
        let dates = [];
        for (let i = 0; i <= daysLeftOnMarket; i++) dates.push(addDays(new Date(), i));
        return dates;
    };

    const getValues = () => {
        switch (user?.userType) {
            case 'agent':
                return { appointmentType: 'FirstShowing' };
            case 'inspector':
                return { appointmentType: 'Inspection' };
            case 'appraiser':
                return { appointmentType: 'Appraisal' };
            case 'maintenance':
                return { appointmentType: 'Maintenance' };
            default:
                return { appointmentType: 'FirstShowing' };
        }
    };

    const timePicker = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: 300,
                marginLeft: 50,
            }}
        >
            <div className={styles.pickerContainer}>
                <span>Start Time</span>
                <select
                    className={styles.timePicker}
                    onChange={(e) => {
                        setStartTime(e.target.value);
                    }}
                >
                    {startTimes.map((time: string) => {
                        const notGoodStartTimes = [
                            '9:00pm',
                            '9:15pm',
                            '9:30pm',
                            '9:45pm',
                            '10:00pm',
                            '10:15pm',
                            '10:30pm',
                            '10:45pm',
                        ];
                        if (!notGoodStartTimes.includes(time))
                            return (
                                <option key={time} value={time}>
                                    {time}
                                </option>
                            );
                    })}
                </select>
            </div>
            <div className={styles.pickerContainer}>
                <span>End Time</span>
                <select
                    className={styles.timePicker}
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                >
                    {endTimesToUse.slice(0, 8).map((time: string, index: number) => {
                        return (
                            <option key={time} value={time}>
                                {time}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
    return (
        <div>
            {!!createdShowing ? (
                <div className={styles.successModal}>
                    <img src={CheckIcon} style={{ marginBottom: '20px' }} />
                    <span>Showing Scheduled!</span>
                </div>
            ) : (
                <>
                    <span className={styles.address}>
                        {selectedListing?.address1} {selectedListing?.city} {selectedListing?.state}
                        , {selectedListing?.zipCode}
                    </span>
                    <div className={styles.outerMostContainer}>
                        <div className={styles.calendarContainer}>
                            <DatePicker
                                selected={selectedStartTime}
                                onChange={(date) => {
                                    setSelectedStartTime(date);
                                    setSelectedEndTime(date);
                                }}
                                dateFormat={'EEEE MMMM d, yyyy'}
                                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                                onKeyDown={(e) => e.preventDefault()}
                                onChangeRaw={(e) => e.preventDefault()}
                                placeholderText={'Pick a Date'}
                                className={styles.calendarDisplayDate}
                                calendarContainer={MyContainer}
                                popperPlacement={'top-start'}
                                includeDates={getAvailableDates()}
                                open={true}
                            />
                        </div>
                        <textarea
                            placeholder={'Request Notes (Optional)'}
                            onChange={(e) => setRequestNotes(e.target.value)}
                            className={styles.requestNotes}
                            maxLength={256}
                        />
                    </div>
                    {timePicker}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: 300,
                            marginLeft: 50,
                            marginTop: 20,
                            alignItems: 'center',
                        }}
                    >
                        <span className={styles.subTitle}>Appointment Method: </span>
                        <select
                            onChange={(e) => setAppointmentMethod(e.target.value)}
                            className={styles.timePicker}
                        >
                            <option value={'InPersonOnly'}>In Person</option>
                            <option value={'VirtualOnly'}>Virtual</option>
                            <option value={'InPersonAndVirtual'}>Both</option>
                        </select>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: 300,
                            marginLeft: 50,
                            marginTop: 20,
                            alignItems: 'center',
                        }}
                    >
                        {user?.userType === 'agent' && (
                            <>
                                <span className={styles.subTitle}>Buyer Client: </span>
                                <select
                                    onChange={(e) => setBuyerClient(e.target.value)}
                                    className={styles.timePicker}
                                >
                                    <option value={'None'}>None</option>
                                    {clients?.map((client: any) => {
                                        return <option value={client?._id}>{client?.name}</option>;
                                    })}
                                </select>
                            </>
                        )}
                    </div>
                    <button
                        onClick={() => {
                            const showingDetails = {
                                showListingId: selectedListing?.showListingId,
                                startDateTime: selectedStartTime.toISOString(),
                                endDateTime: selectedEndTime.toISOString(),
                                appointmentType: getValues().appointmentType,
                                appointmentMethod: appointmentMethod,
                                buyingAgentID: user?.agentMlsId,
                                buyingAgentName: user?.name,
                                buyingAgentStateLicenseAffirmation: true,
                                buyingAgentLicenseNumber: user?.licenseNumber,
                                buyingAgentLicenseState: user?.licenseState || 'CO',
                                buyingAgentMlsId: user?.agentMlsId,
                                requestNotes: requestNotes,
                            };
                            const buyerObjectId =
                                buyerClient === 'None' || !buyerClient ? null : buyerClient;
                            createShowingRequested(
                                updating
                                    ? { ...showingDetails, requestId: passedRequestIds?.requestId }
                                    : showingDetails,
                                buyerObjectId,
                            );
                        }}
                        className={overlap ? styles.disabledButton : styles.scheduleButton}
                        disabled={overlap || loading}
                    >
                        {loading ? 'Scheduling... ' : updating ? 'Update' : 'Schedule'}
                    </button>
                </>
            )}
        </div>
    );
};

export default connector(ScheduleScreen);
