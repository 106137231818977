import { all, call, put, select, takeLatest } from '@redux-saga/core/effects';
import { addShowingToClient, callStitchFunction } from '../../store/api/sagas';
import { STATUS } from '../../utils/common/constants';
import { fetchMyShowingsRequested } from '../myShowings/actions';
import * as Actions from './actions';
import { getUpdating } from './selectors';

export function* searchListings({
    searchParams,
}: ReturnType<typeof Actions.textSearchListingsRequested>): Generator<any, any, any> {
    try {
        // call hubSearchListings
        const { results: listings } = yield call(
            callStitchFunction,
            'hubSearchListings',
            searchParams,
        );
        if (listings?.length) {
            yield put(Actions.textSearchListingsSucceeded(listings));
        } else {
            yield put(Actions.textSearchListingsFailed('There were no listings found'));
        }
    } catch (error) {
        yield put(Actions.textSearchListingsFailed(error));
    }
}

export function* fetchAvailability({
    showListingId,
}: ReturnType<typeof Actions.fetchListingAvailabilityRequested>): Generator<any, any, any> {
    try {
        const availability = yield call(
            callStitchFunction,
            'getListingTimeRestrictions',
            showListingId,
        );
        if (availability) {
            yield put(Actions.fetchListingAvailabilitySucceeded(availability));
        }
    } catch (error) {
        yield put(Actions.fetchListingAvailabilityFailed(error));
    }
}

export function* createShowing({
    showing,
    buyerClient = null,
}: ReturnType<typeof Actions.createShowingRequested>): Generator<any, any, any> {
    try {
        const updating = yield select(getUpdating);

        let endpoint = 'createShowingRequest';

        if (updating) {
            endpoint = 'editShowingRequest';
        }

        const showingRequest = yield call(callStitchFunction, endpoint, showing);

        if (showingRequest?.isSuccessful) {
            if (buyerClient) {
                const requestId = showingRequest.results[0]?.requestId;
                yield call(addShowingToClient, buyerClient, requestId);
            }
            yield put(Actions.createShowingSucceeded(showingRequest));
        } else if (updating) {
            yield put(Actions.createShowingSucceeded(showingRequest));
        }
    } catch (error) {
        yield put(Actions.createShowingFailed(error));
    }
}

export function* cancelShowing({
    showing,
    myShowingParams,
}: ReturnType<typeof Actions.cancelShowingRequested>): Generator<any, any, any> {
    try {
        const cancelledAppointment = yield call(callStitchFunction, 'cancelAppointment', {
            requestId: showing?.id,
            cancelReason: 'Reschedule',
            cancelComments: '',
        });

        if (cancelledAppointment) {
            yield put(Actions.cancelShowingSucceeded());
            yield put(fetchMyShowingsRequested(myShowingParams));
        }
    } catch (error) {
        yield put(Actions.cancelShowingFailed(error));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.SearchListings &&
                action.status === STATUS.Requested,
            searchListings,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.FetchListingAvailability &&
                action.status === STATUS.Requested,
            fetchAvailability,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.CreateShowing &&
                action.status === STATUS.Requested,
            createShowing,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === Actions.SEARCH_ACTION.CancelShowing &&
                action.status === STATUS.Requested,
            cancelShowing,
        ),
    ]);
}
