import { STATUS } from '../../utils/common/constants';

export enum SEARCH_ACTION {
    SearchListings = '[Search] Search Listings',
    UpdateSelectedListing = '[Search] Update Selected Listing',
    ClearSelectedListing = '[Search] Clear Selected Listing',
    FetchListingAvailability = '[Search] Fetch Listing Availability',
    CreateShowing = '[Search] Create Showing',
    ClearCreatedShowing = '[Search] Clear Created Showing',
    SetUpdating = '[Search] Set Updating',
    CancelShowing = '[Search] Cancel Showing',
}

export function textSearchListingsRequested(searchParams: any) {
    return {
        type: SEARCH_ACTION.SearchListings,
        status: STATUS.Requested,
        searchParams,
    };
}

export function textSearchListingsSucceeded(listings: any) {
    return {
        type: SEARCH_ACTION.SearchListings,
        status: STATUS.Succeeded,
        listings,
    };
}

export function textSearchListingsFailed(errors: any) {
    return {
        type: SEARCH_ACTION.SearchListings,
        status: STATUS.Failed,
        errors,
    };
}

export function updateSelectedListing(listing: any) {
    return {
        type: SEARCH_ACTION.UpdateSelectedListing,
        listing,
    };
}

export function clearSelectedListing() {
    return {
        type: SEARCH_ACTION.ClearSelectedListing,
    };
}

export function fetchListingAvailabilityRequested(showListingId: string) {
    return {
        type: SEARCH_ACTION.FetchListingAvailability,
        status: STATUS.Requested,
        showListingId,
    };
}

export function fetchListingAvailabilitySucceeded(availability: any) {
    return {
        type: SEARCH_ACTION.FetchListingAvailability,
        status: STATUS.Succeeded,
        availability,
    };
}

export function fetchListingAvailabilityFailed(errors: any) {
    return {
        type: SEARCH_ACTION.FetchListingAvailability,
        status: STATUS.Failed,
        errors,
    };
}

export function createShowingRequested(showing: any, buyerClient: any | null) {
    return {
        type: SEARCH_ACTION.CreateShowing,
        status: STATUS.Requested,
        showing,
        buyerClient,
    };
}

export function createShowingSucceeded(createdShowing: any) {
    return {
        type: SEARCH_ACTION.CreateShowing,
        status: STATUS.Succeeded,
        createdShowing,
    };
}

export function createShowingFailed(errors: any) {
    return {
        type: SEARCH_ACTION.CreateShowing,
        status: STATUS.Failed,
        errors,
    };
}

export function clearCreatedShowing() {
    return {
        type: SEARCH_ACTION.ClearCreatedShowing,
    };
}

export function setUpdateShowing(passedRequestIds: any) {
    return {
        type: SEARCH_ACTION.SetUpdating,
        passedRequestIds,
    };
}

export function cancelShowingRequested(showing: any, myShowingParams: any) {
    return {
        type: SEARCH_ACTION.CancelShowing,
        status: STATUS.Requested,
        showing,
        myShowingParams,
    };
}

export function cancelShowingSucceeded() {
    return {
        type: SEARCH_ACTION.CancelShowing,
        status: STATUS.Succeeded,
    };
}

export function cancelShowingFailed(errors: any) {
    return {
        type: SEARCH_ACTION.CancelShowing,
        status: STATUS.Failed,
        errors,
    };
}
