import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { connect, ConnectedProps } from 'react-redux';
import BackIcon from '../../../icons/backIcon.svg';
import { fetchListingsRequested, setSelectedListing } from '../actions';
import { getListings, getListingsLoading } from '../selectors';
import { getUserProfile } from '../../auth/selectors';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router';
import { fetchClientsRequested } from '../../clients/actions';
import { getClients } from '../../clients/selectors';

const mapStateToProps = (state: any) => ({
    listingsAndClients: getListings(state),
    loading: getListingsLoading(state),
    user: getUserProfile(state),
    clients: getClients(state),
});

const mapDispatchToProps = {
    fetchListingsRequested: fetchListingsRequested,
    setSelectedListing: setSelectedListing,
    fetchClientsRequested: fetchClientsRequested,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface LoginSignupProps extends ReduxProps {}

function ListingsScreen(props: LoginSignupProps) {
    const {
        fetchListingsRequested,
        fetchClientsRequested,
        clients,
        listingsAndClients,
        loading,
        user,
        setSelectedListing,
    } = props;

    const history = useHistory();

    // fetch listings on page mount
    useEffect(() => {
        fetchListingsRequested();
        fetchClientsRequested();
    }, []);

    const listingsView = (
        <div className={styles.listingsContainer}>
            <button
                className={styles.createListingButton}
                onClick={() => {
                    history.push('/listings/config');
                }}
            >
                Create Listing
            </button>
            {listingsAndClients.map((e: any) => {
                const listing = e?.listing || {};
                var address = listing.address1;
                if (listing.city) address += ` ${listing.city}`;
                if (listing.state) address += `, ${listing.state}`;
                if (listing.zipcode) address += ` ${listing.zipcode}`;

                const showingWindow = `${DateTime.fromJSDate(
                    new Date(listing.showableStartDate),
                ).toFormat('f')} - ${DateTime.fromJSDate(
                    new Date(listing.showableEndDate),
                ).toFormat('f')}`;

                const getClient = () => {
                    if (clients?.length && e.clientId) {
                        for (let i = 0; i < clients.length; i++) {
                            if (JSON.stringify(clients[i]._id) === e.clientId) {
                                return clients[i].name;
                            }
                        }
                    }
                    return 'None';
                };

                return (
                    <div className={styles.listCard}>
                        <div className={styles.metaData}>
                            <div className={styles.addressText}>{address}</div>
                            <div>{`Listing Mls Id: ${listing.listingId}`}</div>
                            <div>{`List Agent License: ${listing.listAgentLicenseNumber}`}</div>
                            <div>{`List Agent Mls Id: ${listing.listAgentMlsId}`}</div>
                            <div>{`List Agent Name: ${listing.listAgentName}`}</div>
                            <div>{`Upi: ${listing.upi}`}</div>
                        </div>
                        <div className={styles.metaData}>
                            <div>{`Showings allowed: ${listing.showingsAllowed}`}</div>
                            <div>{`Confirmation type: ${listing.confirmationType}`}</div>
                            <div>{`Required participants: ${listing.requiredParticipants}`}</div>
                            <div>{`Showing method: ${listing.showingMethod}`}</div>
                            <div>{`Showable window: ${showingWindow}`}</div>
                            <div>{`Seller: ${getClient()}`}</div>
                        </div>

                        {user?.userType === 'agent' && (
                            <div className={styles.buttonContainer}>
                                <button
                                    className={styles.button}
                                    onClick={() => {
                                        setSelectedListing(e);
                                        history.push('/listings/config');
                                    }}
                                >
                                    Configure
                                </button>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className={styles.root}>
            <div className={styles.pageHeader}>Listings</div>
            {loading ? <div>...Loading listings</div> : listingsView}
        </div>
    );
}

export const ConnectedListingsScreen = connector(ListingsScreen);
