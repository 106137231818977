import { STATUS } from '../../utils/common/constants';

export enum AUTH_ACTION {
    Initialize = '[Auth] Initialize',
    Create = '[Auth] Create',
    UserLogin = '[Auth] User Login',
    UserLogout = '[Auth] User Logout',
    SetClient = '[Auth] Set Client',
    SetRealmUserId = '[Auth] Set Realm User Id',
    SetUserRecord = '[Auth] Set User Record',
}

export function initializeRequested() {
    return {
        type: AUTH_ACTION.Initialize,
        status: STATUS.Requested,
        initializing: true,
    };
}

export function initializeSucceeded(isLoggedIn: boolean) {
    return {
        type: AUTH_ACTION.Initialize,
        status: STATUS.Succeeded,
        initializing: false,
        isLoggedIn,
    };
}

export function initializeFailed() {
    return {
        type: AUTH_ACTION.Initialize,
        status: STATUS.Failed,
    };
}

export function createNewUserRequested(user: any) {
    return {
        type: AUTH_ACTION.Create,
        status: STATUS.Requested,
        user,
    };
}
export function createNewUserSucceeded() {
    return {
        type: AUTH_ACTION.Create,
        status: STATUS.Succeeded,
    };
}
export function createNewUserFailed(errors: any) {
    return {
        type: AUTH_ACTION.Create,
        status: STATUS.Failed,
        errors,
    };
}

export function userLogoutRequested() {
    return {
        type: AUTH_ACTION.UserLogout,
        status: STATUS.Requested,
    };
}

export function userLoginRequested(userInfo: any) {
    return {
        type: AUTH_ACTION.UserLogin,
        status: STATUS.Requested,
        userInfo,
    };
}
export function userLoginSucceeded() {
    return {
        type: AUTH_ACTION.UserLogin,
        status: STATUS.Succeeded,
    };
}
export function userLoginFailed(error: any) {
    return {
        type: AUTH_ACTION.UserLogin,
        status: STATUS.Failed,
        error,
    };
}

export function setClient(client: any) {
    return {
        type: AUTH_ACTION.SetClient,
        client,
    };
}

export function setRealmUserId(realmUserId: any) {
    return {
        type: AUTH_ACTION.SetRealmUserId,
        realmUserId,
    };
}

export function setUserRecord(userRecord: any) {
    return {
        type: AUTH_ACTION.SetUserRecord,
        userRecord,
    };
}

export type AuthenticationAction =
    | ReturnType<typeof initializeRequested>
    | ReturnType<typeof initializeSucceeded>
    | ReturnType<typeof initializeFailed>;
