import { Drawer, List, ListItem, ListItemIcon, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import Icon from './appicon.png';

import styles from './index.module.css';

const useStyles = makeStyles(() => ({
    drawerPaper: {
        width: 'inherit',
        'box-shadow': '0px 2px 4px #00000066',
        'align-items': 'center',
    },
}));

type ListItemProp = {
    link: string;
    icon?: string;
    iconSelected?: string;
    pageTitle?: string;
    beta?: boolean;
};

interface SideBarProps extends RouteComponentProps {
    ListItems: ListItemProp[];
    iconStyling?: string;
    iconTextStyling?: string;
    customWaterMark?: string;
    customWaterMarkStyling?: string;
    userName: string;
    logOut: Function;
}

const SideBar = (props: SideBarProps) => {
    const [path, setPath] = useState('');
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        setPath(window.location.pathname);
    }, [window.location.pathname]);

    const iconStyles = props.iconStyling ? classnames(styles.icon, props.iconStyling) : styles.icon;

    // the component takes in an array of icons and relative links
    // this function converts that type into a usable side icon to click on
    const getListItems = () => {
        return props.ListItems.map((item: ListItemProp, index: number) => {
            return (
                //DO NOT DELETE THIS LINK CONTAINER! If this is deleted the Link component will overflow and make the sidebar horizontally scroll able
                <div className={styles.linkContainer} key={index}>
                    <Link
                        key={item.link}
                        to={item.link}
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                            history.push(item.link);
                            setPath(item.link);
                        }}
                    >
                        <ListItem button={true}>
                            <div className={styles.iconAndName}>
                                <p
                                    className={
                                        path === item.link || path.includes(item.link)
                                            ? classnames(
                                                  styles.pageNameSelected,
                                                  props.iconTextStyling,
                                              )
                                            : classnames(styles.pageName, props.iconTextStyling)
                                    }
                                >
                                    {item.pageTitle ? item.pageTitle : ''}
                                </p>
                            </div>
                        </ListItem>
                    </Link>
                </div>
            );
        });
    };

    return (
        <div className={styles.routerMainDiv}>
            <Drawer
                className={styles.drawerMain}
                variant="persistent"
                anchor="left"
                open={true}
                classes={{ paper: classes.drawerPaper }}
            >
                <List className={styles.list}>
                    <div className={styles.navBarElements}>
                        <div className={styles.wordmarkContainer}>
                            <img
                                src={Icon}
                                alt={'Showing Hack Word Mark'}
                                className={styles.wordmarkImage}
                            />
                        </div>
                        <div className={styles.spacer}></div>
                        {getListItems()}
                    </div>
                    <div className={styles.settingsAndProfileContainer}>
                        <p className={styles.userNameStyling}>{props.userName}</p>
                        <p onClick={() => props.logOut()} className={styles.logOutText}>
                            Log Out
                        </p>
                    </div>
                </List>
            </Drawer>
        </div>
    );
};

export const ConnectedSideBar = withRouter(SideBar);
