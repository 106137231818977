import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { connect, ConnectedProps } from 'react-redux';
import BackIcon from '../../../icons/backIcon.svg';
import { deleteClientRequested, fetchClientsRequested } from '../actions';
import { getClients, getClientsLoading, getDeletingClient } from '../selectors';
import { getUserProfile } from '../../auth/selectors';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';

const mapStateToProps = (state: any) => ({
    clients: getClients(state),
    loading: getClientsLoading(state),
    deletingClient: getDeletingClient(state),
    user: getUserProfile(state),
});

const mapDispatchToProps = {
    fetchClientsRequested: fetchClientsRequested,
    deleteClientRequested: deleteClientRequested,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface LoginSignupProps extends ReduxProps {}

function ClientsScreen(props: LoginSignupProps) {
    const { fetchClientsRequested, clients, loading, user, deleteClientRequested, deletingClient } =
        props;

    const history = useHistory();
    const [clientsToMap, setClientsToMap] = useState([...clients]);

    // fetch clients on page mount
    useEffect(() => {
        fetchClientsRequested();
    }, []);
    // fetch clients on page mount
    useEffect(() => {
        setClientsToMap([...clients]);
    }, [clients.length]);

    const clientsView = (
        <div className={styles.clientsContainer}>
            {clientsToMap.map((client: any) => {
                return (
                    <div className={styles.listCard}>
                        <div className={styles.info}>
                            <div>{client.name}</div>
                            <div>{client.phone}</div>
                            <div>{client.email}</div>
                        </div>
                        <button
                            onClick={
                                deletingClient ? () => {} : () => deleteClientRequested(client._id)
                            }
                            className={styles.deleteButton}
                        >
                            {deletingClient === client._id ? 'Deleting...' : 'Delete'}
                        </button>
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className={styles.root}>
            <div className={styles.pageHeader}>Clients</div>
            {clientsView}
            <div className={styles.buttonContainer}>
                <button
                    onClick={() => history.push('/clients/addClient')}
                    className={styles.button}
                >
                    Add Client
                </button>
            </div>
        </div>
    );
}

export const ConnectedClientsScreen = connector(ClientsScreen);
