import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux';
import { getInitializing, getIsLoggedIn } from './domains/auth/selectors';
import { ConnectedLoginSignup } from './domains/auth/ConnectedLoginSignUp';
import MainApp from './navigation/main-app';

function App({ initializing, isLoggedIn }) {
    if (!isLoggedIn) {
        return <ConnectedLoginSignup />;
    }
    return <MainApp />;
}

const mapStateToProps = (state) => ({
    initializing: getInitializing(state),
    isLoggedIn: getIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
