import { all, call, put, select, takeLatest } from '@redux-saga/core/effects';
import { callStitchFunction, getUserRecord, updateUserDoc } from '../../store/api/sagas';
import { USERS_COLLECTION } from '../../store/constants';
import { getRealmUserId, getUserProfile } from '../auth/selectors';
import * as LISTING_ACTIONS from './actions';
import { DateTime } from 'luxon';
import { setUserRecord } from '../auth/actions';

/**
 * Fetch all listings associated with the given user
 */
export function* fetchListing({}): Generator<any, any, any> {
    try {
        const realmUserId = yield select(getRealmUserId);
        const user = yield call(getUserRecord, realmUserId);
        if (user?._id) {
            const listings = yield call(callStitchFunction, 'getUserListings', user._id);
            yield put(LISTING_ACTIONS.fetchListingsSucceeded(listings));
        }
    } catch (err) {
        yield put(LISTING_ACTIONS.fetchListingsFailed(err));
    }
}

/**
 * Fetch the time restrctions for an associated listing
 */
export function* fetchListingAvailability({
    showListingId,
}: ReturnType<typeof LISTING_ACTIONS.fetchAvailabilityRequested>): Generator<any, any, any> {
    try {
        const availability = yield call(
            callStitchFunction,
            'getListingTimeRestrictions',
            showListingId,
        );
        if (availability) {
            yield put(LISTING_ACTIONS.fetchAvailabilitySucceeded(availability));
        } else {
            yield put(LISTING_ACTIONS.fetchAvailabilityFailed(''));
        }
    } catch (err) {
        yield put(LISTING_ACTIONS.fetchAvailabilityFailed(err));
    }
}

/**
 * Create a set of rules for a given listing
 */
export function* createListingRule({
    rules,
}: ReturnType<typeof LISTING_ACTIONS.createListingRuleRequested>): Generator<any, any, any> {
    try {
        //
    } catch (err) {
        //
    }
}

/**
 * Configure the listing data changes
 */
export function* listingConfig({
    metaData,
    rules,
    showListingId,
    attachedClientId,
}: ReturnType<typeof LISTING_ACTIONS.listingConfigRequested>): Generator<any, any, any> {
    try {
        const realmUserId = yield select(getRealmUserId);
        const user = yield select(getUserProfile);

        var restrictionData: any = []; // used for updating rules
        var dateTimeRestrictionsList: any = []; // used for create/edit entire listing function
        var dateTimeReoccurringRestrictionsList: any = []; // used for create/edit entire listing function

        if (rules?.length) {
            // convert rule data into restriction fields
            rules?.map((r: any) => {
                var obj: any = null;
                if (r.reoccurring) {
                    obj = {
                        type: r.type || 'create',
                        reoccurring: [
                            {
                                reoccurringId: r.id || 0,
                                startDatetime: r.startDatetime,
                                endDatetime: r.endDatetime,
                                numberOfWeeks: r.numberOfWeeks,
                                beginDate: r.beginDate,
                                dayOfWeek: DateTime.fromJSDate(new Date(r.beginDate)).toFormat(
                                    'cccc',
                                ),
                            },
                        ],
                    };
                    if (r.type === 'remove') {
                        obj.reoccurring = obj.reoccurring[0];
                    }
                    dateTimeReoccurringRestrictionsList.push(obj.reoccurring);
                } else {
                    obj = {
                        type: r.type || 'create',
                        individual: {
                            restrictionId: r.id || 0,
                            startDatetime: r.startDatetime,
                            endDatetime: r.endDatetime,
                        },
                    };
                    dateTimeRestrictionsList.push(obj.individual);
                }
                if (obj.type !== 'update') {
                    // don't use updates
                    restrictionData.push(obj);
                }
            });
            restrictionData.push();
        }

        // check if creating or updating
        if (showListingId) {
            // create/update rules

            if (restrictionData?.length) {
                const RESP = yield call(
                    callStitchFunction,
                    'createOrUpdateShowingAvailability',
                    showListingId,
                    restrictionData,
                );
            }

            /**
             * Here we intentionally give them the following duplicate fields because
             * we different key names are required in different places:
             *
             * showingStatus    =   showingsAllowed
             * upi              =   universalPropertyId
             */
            var modifiedSchema = { ...metaData };
            modifiedSchema.showingsAllowed = modifiedSchema.showingStatus;

            // update non rule fields
            const editRESP = yield call(callStitchFunction, 'editListing', showListingId, metaData);

            // user added a client, so must add to user listing documents
            if (attachedClientId) {
                var associatedListings: any = [];
                if (user?.associatedListings?.length) {
                    associatedListings = [...user.associatedListings];
                }
                associatedListings.map((listing: any, index: number) => {
                    if (listing.showListingId === showListingId) {
                        associatedListings[index] = {
                            showListingId,
                            clientId: attachedClientId,
                        };
                    }
                });
                yield call(updateUserDoc, realmUserId, {
                    associatedListings,
                });
                // fetch the updated user
                const userRecord = yield call(getUserRecord, user.realmUserId);
                yield put(setUserRecord(userRecord));
            }
        } else {
            var listingMetaData = {
                ...metaData,
                dateTimeRestrictionsList,
                dateTimeReoccurringRestrictionsList,
            };
            // create new listing
            const RESP = yield call(callStitchFunction, 'createListing', listingMetaData);
            // if listing is successfully created, attach is to the agent
            if (RESP?.isSuccessful && RESP.results?.length) {
                const showListingId = RESP.results[0]?.showListingId;
                var associatedListings: any = [];
                if (user?.associatedListings?.length) {
                    associatedListings = [...user.associatedListings];
                }
                associatedListings.push({
                    showListingId,
                    clientId: attachedClientId || null,
                });
                yield call(updateUserDoc, realmUserId, {
                    associatedListings,
                });
                // fetch the updated user
                const userRecord = yield call(getUserRecord, user.realmUserId);
                yield put(setUserRecord(userRecord));
            }
        }
        yield put(LISTING_ACTIONS.fetchAvailabilityRequested(showListingId));
        yield put(LISTING_ACTIONS.fetchListingsRequested());
        yield put(LISTING_ACTIONS.listingConfigSucceeded());
    } catch (err) {
        yield put(LISTING_ACTIONS.listingConfigFailed(err));
    }
}
// type: 'create' | 'update',
// individual: { restrictionId: number | null, startDatetime, endDatetime } | null,
// reoccurring: { reoccurringId: number | null, startDatetime, endDatetime, numberOfWeeks, dayOfWeek: string, beginDate }

// const [newRuleConfig, setNewRuleConfig] = useState<any>({
//     beginDate: null,
//     startDatetime: null,
//     endDatetime: null,
//     numberOfWeeks: 0,
//     reoccurring: false,
// });

export default function* (): Generator<any, any, any> {
    yield all([takeLatest(LISTING_ACTIONS.FETCH_LISTINGS_REQUESTED, fetchListing)]);
    yield all([takeLatest(LISTING_ACTIONS.FETCH_AVAILABILITY_REQUESTED, fetchListingAvailability)]);
    yield all([takeLatest(LISTING_ACTIONS.CREATE_LISTING_RULE_REQUESTED, createListingRule)]);
    yield all([takeLatest(LISTING_ACTIONS.LISTING_CONFIG_REQUESTED, listingConfig)]);
}
