import { STATUS } from '../../utils/common/constants';

export enum MY_SHOWINGS_ACTION {
    FetchMyShowings = '[My Showings] Fetch My Showings',
    ConfirmShowing = '[My Showings] Confirm Showing',
    DenyShowing = '[My Showings] Deny Showing',
}

export function fetchMyShowingsRequested(params: any) {
    return {
        type: MY_SHOWINGS_ACTION.FetchMyShowings,
        status: STATUS.Requested,
        params,
    };
}

export function fetchMyShowingsSucceeded(myShowings: any) {
    return {
        type: MY_SHOWINGS_ACTION.FetchMyShowings,
        status: STATUS.Succeeded,
        myShowings,
    };
}

export function fetchMyShowingsFailed(errors: any) {
    return {
        type: MY_SHOWINGS_ACTION.FetchMyShowings,
        status: STATUS.Failed,
        errors,
    };
}

export function confirmShowingRequested(requestId: any) {
    return {
        type: MY_SHOWINGS_ACTION.ConfirmShowing,
        status: STATUS.Requested,
        requestId,
    };
}

export function confirmShowingSucceeded() {
    return {
        type: MY_SHOWINGS_ACTION.ConfirmShowing,
        status: STATUS.Succeeded,
    };
}

export function confirmShowingFailed(errors: any) {
    return {
        type: MY_SHOWINGS_ACTION.ConfirmShowing,
        status: STATUS.Failed,
        errors,
    };
}

export function denyShowingRequested(requestId: any) {
    return {
        type: MY_SHOWINGS_ACTION.DenyShowing,
        status: STATUS.Requested,
        requestId,
    };
}

export function denyShowingSucceeded() {
    return {
        type: MY_SHOWINGS_ACTION.DenyShowing,
        status: STATUS.Succeeded,
    };
}

export function denyShowingFailed(errors: any) {
    return {
        type: MY_SHOWINGS_ACTION.DenyShowing,
        status: STATUS.Failed,
        errors,
    };
}
