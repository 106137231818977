import { combineReducers } from 'redux';
import auth from '../domains/auth/reducer';
import search from '../domains/ListingSearch/reducer';
import listings from '../domains/listings/reducer';
import myShowings from '../domains/myShowings/reducer';
import clients from '../domains/clients/reducer';

export default combineReducers({
    auth,
    clients,
    search,
    listings,
    myShowings,
});
