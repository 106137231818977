export const LISTINGS_PREFIX = 'listing/';

// =====================================================================================================

export const FETCH_LISTINGS_REQUESTED = LISTINGS_PREFIX + 'FETCH_LISTINGS_REQUESTED';
export const FETCH_LISTINGS_SUCCEEDED = LISTINGS_PREFIX + 'FETCH_LISTINGS_SUCCEEDED';
export const FETCH_LISTINGS_FAILED = LISTINGS_PREFIX + 'FETCH_LISTINGS_FAILED';

export const fetchListingsRequested = () => {
    return {
        type: FETCH_LISTINGS_REQUESTED,
    } as const;
};
export const fetchListingsSucceeded = (listings: any[]) => {
    return {
        type: FETCH_LISTINGS_SUCCEEDED,
        listings,
    } as const;
};
export const fetchListingsFailed = (errors: any) => {
    return {
        type: FETCH_LISTINGS_FAILED,
        errors,
    } as const;
};

// =====================================================================================================

export const FETCH_AVAILABILITY_REQUESTED = LISTINGS_PREFIX + 'FETCH_AVAILABILITY_REQUESTED';
export const FETCH_AVAILABILITY_SUCCEEDED = LISTINGS_PREFIX + 'FETCH_AVAILABILITY_SUCCEEDED';
export const FETCH_AVAILABILITY_FAILED = LISTINGS_PREFIX + 'FETCH_AVAILABILITY_FAILED';

export const fetchAvailabilityRequested = (showListingId: any) => {
    return {
        type: FETCH_AVAILABILITY_REQUESTED,
        showListingId,
    } as const;
};
export const fetchAvailabilitySucceeded = (availability: any) => {
    return {
        type: FETCH_AVAILABILITY_SUCCEEDED,
        availability,
    } as const;
};
export const fetchAvailabilityFailed = (errors: any) => {
    return {
        type: FETCH_AVAILABILITY_FAILED,
        errors,
    } as const;
};

// =====================================================================================================

export const CREATE_LISTING_RULE_REQUESTED = LISTINGS_PREFIX + 'CREATE_LISTING_RULE_REQUESTED';
export const CREATE_LISTING_RULE_SUCCEEDED = LISTINGS_PREFIX + 'CREATE_LISTING_RULE_SUCCEEDED';
export const CREATE_LISTING_RULE_FAILED = LISTINGS_PREFIX + 'CREATE_LISTING_RULE_FAILED';

export const createListingRuleRequested = (rules: any[]) => {
    return {
        type: CREATE_LISTING_RULE_REQUESTED,
        rules,
    } as const;
};
export const createListingRuleSucceeded = () => {
    return {
        type: CREATE_LISTING_RULE_SUCCEEDED,
    } as const;
};
export const createListingRuleFailed = (errors: any) => {
    return {
        type: CREATE_LISTING_RULE_FAILED,
        errors,
    } as const;
};

// =====================================================================================================

export const SET_SELECTED_LISTING = LISTINGS_PREFIX + 'SET_SELECTED_LISTING';

export const setSelectedListing = (listing: any) => {
    return {
        type: SET_SELECTED_LISTING,
        listing,
    } as const;
};

// =====================================================================================================

export const CLEAR_AVAILABILITY = LISTINGS_PREFIX + 'CLEAR_AVAILABILITY';

export const clearAvailability = () => {
    return {
        type: CLEAR_AVAILABILITY,
    };
};

// =====================================================================================================

export const CLEAR_SUCCESS_MARKER = LISTINGS_PREFIX + 'CLEAR_SUCCESS_MARKER';

export const clearSuccessMarker = () => {
    return {
        type: CLEAR_SUCCESS_MARKER,
    };
};

// =====================================================================================================

export const LISTING_CONFIG_REQUESTED = LISTINGS_PREFIX + 'LISTING_CONFIG_REQUESTED';
export const LISTING_CONFIG_SUCCEEDED = LISTINGS_PREFIX + 'LISTING_CONFIG_SUCCEEDED';
export const LISTING_CONFIG_FAILED = LISTINGS_PREFIX + 'LISTING_CONFIG_FAILED';

export const listingConfigRequested = (
    metaData: any,
    rules: any,
    showListingId?: string,
    attachedClientId?: string | null,
) => {
    return {
        type: LISTING_CONFIG_REQUESTED,
        metaData,
        rules,
        showListingId,
        attachedClientId,
    };
};
export const listingConfigSucceeded = () => {
    return {
        type: LISTING_CONFIG_SUCCEEDED,
    };
};
export const listingConfigFailed = (errors: any) => {
    return {
        type: LISTING_CONFIG_FAILED,
        errors,
    };
};
