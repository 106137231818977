import { DateTime } from 'luxon';
import React, { useState } from 'react';
import styles from './index.module.css';

interface Props {
    showing: any;
    denyShowing: any;
    confirmShowing: any;
}

const ShowingCardList = ({ showing, denyShowing, confirmShowing }: Props) => {
    const formatTime = (time: any) => {
        const date = new Date(time);
        return `${DateTime.fromJSDate(date).toFormat('ccc')} ${DateTime.fromJSDate(date).toFormat(
            'LLL',
        )} ${DateTime.fromJSDate(date).toFormat('d')} ${DateTime.fromJSDate(date).toFormat('t')}`;
    };
    const [activeAction, setActiveAction] = useState<any>(null);
    const formatAppointmentType = (type: string) => {
        switch (type) {
            case 'FirstShowing':
                return 'Agent';
            case 'Inspection':
                return 'Inspection';
            case 'Appraisal':
                return 'Appraisal';
            case 'Maintenance':
                return 'Maintenance';
            default:
                return type;
        }
    };
    return (
        <div className={styles.root}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className={styles.addressText}>
                    {showing?.address1}, {showing?.city} {showing?.state}, {showing?.zipCode}
                </span>
                <span>Status: {showing?.requestStatus}</span>
            </div>
            <div className={styles.seperator} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 25,
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', width: 400 }}>
                    <span className={styles.infoText}>
                        {formatTime(showing?.startDatetime)} - {formatTime(showing?.endDatetime)}
                    </span>
                    <span className={styles.infoText}>
                        Confirmation Type: {showing?.confirmationType}
                    </span>
                    <span className={styles.infoText}>
                        Showing Method: {showing?.showingMethod}
                    </span>
                    <span className={styles.infoText}>
                        Appointment Type: {formatAppointmentType(showing?.appointmentType)}
                    </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: 500 }}>
                    <span className={styles.infoText}>
                        Buying Agent: {showing?.buyingAgentName}
                    </span>
                    <span className={styles.infoText}>Listing Id: {showing?.listingId}</span>
                    <span className={styles.infoText}>
                        Buying Agent MLS Id: {showing?.buyingAgentMlsId}
                    </span>
                    <span className={styles.infoText}>
                        Buying Agent License Number: {showing?.buyingAgentLicenseNumber}
                    </span>
                </div>
                {showing?.requestStatus === 'Requested' ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <button
                            onClick={() => {
                                confirmShowing();
                                setActiveAction('confirming');
                            }}
                            className={styles.updateButton}
                        >
                            {activeAction === 'confirming' ? 'Confirming...' : 'Confirm'}
                        </button>
                        <button
                            onClick={() => {
                                setActiveAction('denying');
                                denyShowing();
                            }}
                            className={styles.denyButton}
                        >
                            {activeAction === 'denying' ? 'Denying...' : 'Deny'}
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export default ShowingCardList;
