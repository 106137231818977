import * as CLIENT_ACTIONS from './actions';

export const initialState = {
    loading: false,
    addingClient: false,
    deletingClient: false,
    addClientSuccess: null,
    clients: [],
    errors: [],
};

export default function (state = initialState, action: any) {
    const { type } = action;
    switch (type) {
        case CLIENT_ACTIONS.FETCH_CLIENTS_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case CLIENT_ACTIONS.FETCH_CLIENTS_SUCCEEDED:
            const { clients } = action;
            return {
                ...state,
                clients,
                loading: false,
            };
        case CLIENT_ACTIONS.FETCH_CLIENTS_FAILED:
            const { errors } = action;
            return {
                ...state,
                errors,
                loading: false,
            };
        case CLIENT_ACTIONS.ADD_CLIENT_REQUESTED:
            return {
                ...state,
                addingClient: true,
            };
        case CLIENT_ACTIONS.ADD_CLIENT_SUCCEEDED:
            return {
                ...state,
                addingClient: false,
                addClientSuccess: true,
            };
        case CLIENT_ACTIONS.ADD_CLIENT_FAILED:
            const { errors: addClientErrors } = action;
            return {
                ...state,
                errors: addClientErrors,
                addingClient: false,
                addClientSuccess: false,
            };
        case CLIENT_ACTIONS.DELETE_CLIENT_REQUESTED:
            const { clientId } = action;
            return {
                ...state,
                deletingClient: clientId,
            };
        case CLIENT_ACTIONS.DELETE_CLIENT_SUCCEEDED:
            return {
                ...state,
                deletingClient: null,
            };
        case CLIENT_ACTIONS.DELETE_CLIENT_FAILED:
            const { errors: deleteClientErrors } = action;
            return {
                ...state,
                errors: deleteClientErrors,
                deletingClient: null,
            };
        case CLIENT_ACTIONS.CLEAR_ADD_CLIENT_SUCCESS:
            return {
                ...state,
                addClientSuccess: null,
            };
        default:
            return state;
    }
}
