import { createSelector } from 'reselect';

export const getAuthState = (state: any) => state.auth || {};

/**
 * Get general Auth Loading status
 * @param state
 * @returns {*}
 */
export const getInitializing = createSelector(getAuthState, (auth) => auth.initializing);

export const getIsLoggedIn = createSelector(getAuthState, (auth) => auth.isLoggedIn);

export const getUser = createSelector(getAuthState, (auth) => auth.user);

export const getRealmUserId = createSelector(getAuthState, (auth) => auth.realmUserId);

export const getUserProfile = createSelector(getAuthState, (auth) => auth.user);

export const getLoginError = createSelector(getAuthState, (auth) => auth.loginError);
