import React from 'react';
import styles from './index.module.css';
import { connect, ConnectedProps } from 'react-redux';

interface Props {
    listing: any;
    onClickSchedule: Function;
}

const ListingCard = ({ listing, onClickSchedule }: Props) => {
    const formatConfirmation = (type: string) => {
        switch (type) {
            case 'ConfirmationRequired': {
                return 'Confirmation Required';
            }
            case 'AutoApprove': {
                return 'Auto Approve';
            }
            default:
                return type;
        }
    };

    return (
        <div className={styles.root}>
            <span className={styles.addressText}>
                {listing?.address1} {listing?.city} {listing?.state}, {listing?.zipCode}
            </span>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{ width: 400 }}>
                    <div style={{ marginBottom: 2 }}>
                        <span className={styles.infoTitle}>Listing Agent:</span>
                        <span className={styles.infoText}> {listing?.listAgentName}</span>
                    </div>
                    <div style={{ marginBottom: 2 }}>
                        <span className={styles.infoTitle}>Listing Id:</span>
                        <span className={styles.infoText}> {listing?.listingId}</span>
                    </div>
                    <div style={{ marginBottom: 2 }}>
                        <span className={styles.infoTitle}>Listing Agent MLS Id:</span>
                        <span className={styles.infoText}> {listing?.listAgentMlsId}</span>
                    </div>
                    <div style={{ marginBottom: 2 }}>
                        <span className={styles.infoTitle}>Listing Agent License #:</span>
                        <span className={styles.infoText}> {listing?.listAgentLicenseNumber}</span>
                    </div>
                </div>
                <div
                    style={{
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        alignSelf: 'flex-start',
                        textAlign: 'start',
                        width: 400,
                    }}
                >
                    <div style={{ marginBottom: 2 }}>
                        <span className={styles.infoTitle}>Showing Method:</span>
                        <span className={styles.infoText}> {listing?.showingMethod}</span>
                    </div>
                    <div style={{ marginBottom: 2 }}>
                        <span className={styles.infoTitle}>Confirmation Type:</span>
                        <span className={styles.infoText}>
                            {' '}
                            {formatConfirmation(listing?.confirmationType)}
                        </span>
                    </div>
                    <div style={{ marginBottom: 2 }}>
                        <span className={styles.infoTitle}>Required Participants:</span>
                        <span className={styles.infoText}> {listing?.requiredParticipants}</span>
                    </div>
                    <div style={{ marginBottom: 2 }}>
                        <span className={styles.infoTitle}>UPI:</span>
                        <span className={styles.infoText}> {listing?.upi}</span>
                    </div>
                </div>
                <button onClick={() => onClickSchedule()} className={styles.scheduleButton}>
                    Schedule
                </button>
            </div>
        </div>
    );
};
export default ListingCard;
