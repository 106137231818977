import { createSelector } from 'reselect';

export const getClientsState = (state: any) => state.clients || {};

export const getClientsLoading = createSelector(getClientsState, (clients) => clients.loading);

export const getAddingClient = createSelector(getClientsState, (clients) => clients.addingClient);

export const getDeletingClient = createSelector(
    getClientsState,
    (clients) => clients.deletingClient,
);

export const getClients = createSelector(getClientsState, (clients) => clients.clients);

export const getAddClientSuccess = createSelector(
    getClientsState,
    (clients) => clients.addClientSuccess,
);
