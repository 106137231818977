export const timesArr: any = [];

for (let i = 5; i <= 22; i++) {
    let suffix = 'pm';
    if (i < 12) suffix = 'am';
    for (let j = 0; j <= 45; j += 15) {
        timesArr.push(`${i <= 12 ? i : i - 12}:${j === 0 ? '00' : j}${suffix}`);
    }
}

export const endTimesArr: any = [];

for (let i = 5; i <= 22; i++) {
    let suffix = 'pm';
    if (i < 12) suffix = 'am';
    for (let j = 0; j <= 45; j += 15) {
        endTimesArr.push(`${i <= 12 ? i : i - 12}:${j === 0 ? '00' : j}${suffix}`);
    }
}
