import { DateTime } from 'luxon';
import React from 'react';
import styles from './index.module.css';

interface Props {
    showing: any;
    onClickUpdate: Function;
    onClickCancel: Function;
}

const ShowingCard = ({ showing, onClickUpdate, onClickCancel }: Props) => {
    const formatTime = (time: any) => {
        const date = new Date(time);
        return `${DateTime.fromJSDate(date).toFormat('ccc')} ${DateTime.fromJSDate(date).toFormat(
            'LLL',
        )} ${DateTime.fromJSDate(date).toFormat('d')} ${DateTime.fromJSDate(date).toFormat('t')}`;
    };

    const isNotReschedule =
        showing?.requestStatus !== 'Cancelled' && showing?.requestStatus !== 'Denied';

    const formatAppointmentType = (type: string) => {
        switch (type) {
            case 'FirstShowing':
                return 'Agent';
            case 'Inspection':
                return 'Inspection';
            case 'Appraisal':
                return 'Appraisal';
            case 'Maintenance':
                return 'Maintenance';
            default:
                return type;
        }
    };

    return (
        <div className={styles.root}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className={styles.addressText}>
                    {showing?.address1}, {showing?.city} {showing?.state}, {showing?.zipCode}
                </span>
                <span>Status: {showing?.requestStatus}</span>
            </div>
            <div className={styles.seperator} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 20,
                    alignItems: 'center',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', width: 400 }}>
                    <span className={styles.infoText}>
                        {formatTime(showing?.startDatetime)} - {formatTime(showing?.endDatetime)}
                    </span>
                    <span className={styles.infoText}>
                        Confirmation Type: {showing?.confirmationType}
                    </span>
                    <span className={styles.infoText}>
                        Showing Method: {showing?.showingMethod}
                    </span>
                    <span className={styles.infoText}>
                        Appointment Type: {formatAppointmentType(showing?.appointmentType)}
                    </span>
                    {showing?.consumer ? (
                        <span className={styles.infoText}>Buyer: {showing.consumer.name}</span>
                    ) : null}
                    {showing?.comments && (
                        <span className={styles.infoText}>Comments: {showing?.comments}</span>
                    )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: 400 }}>
                    <span className={styles.infoText}>Listing Agent: {showing?.listAgentName}</span>
                    <span className={styles.infoText}>Listing Id: {showing?.listingId}</span>
                    <span className={styles.infoText}>
                        Listing Agent MLS Id: {showing?.listAgentMlsId}
                    </span>
                    <span className={styles.infoText}>
                        Listing Agent License Number: {showing?.listAgentLicenseNumber}
                    </span>
                    {showing?.showingInstructions && (
                        <span className={styles.infoText}>
                            Showing Instructions: {showing?.showingInstructions}
                        </span>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 10,
                    }}
                >
                    <button
                        onClick={() => onClickUpdate(isNotReschedule)}
                        className={styles.updateButton}
                    >
                        {showing?.requestStatus !== 'Cancelled' &&
                        showing?.requestStatus !== 'Denied'
                            ? 'Update'
                            : 'Reschedule'}
                    </button>
                    {showing?.requestStatus !== 'Cancelled' &&
                    showing?.requestStatus !== 'Denied' ? (
                        <button onClick={() => onClickCancel()} className={styles.cancelButton}>
                            Cancel
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default ShowingCard;
