import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { connect, ConnectedProps } from 'react-redux';
import BackIcon from '../../../icons/backIcon.svg';
import { createNewUserRequested, userLoginRequested } from '../actions';
import { scrubAndFormatPhoneNumber } from '../../../utils/common/constants';
import { getLoginError } from '../selectors';

const mapStateToProps = (state: any) => ({
    loginError: getLoginError(state),
});

const mapDispatchToProps = {
    createNewUserRequested: createNewUserRequested,
    userLoginRequested: userLoginRequested,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
interface LoginSignupProps extends ReduxProps {}

function LoginSignupScreen({
    createNewUserRequested,
    userLoginRequested,
    loginError,
}: LoginSignupProps) {
    const [signUpType, setSignUpType] = useState<any>({});
    const [loggingIn, setLoggingIn] = useState<any>(false);
    const [userInfo, setUserInfo] = useState<any>({
        agentMlsId: (Math.random() * 10000000000).toFixed(0),
    });
    useEffect(() => {}, [loginError]);
    const userTypes = [
        {
            type: 'Agent',
            color: '#986df4',
        },
        {
            type: 'Home Inspector',
            color: '#2fd288',
        },
        {
            type: 'Appraiser',
            color: '#48a4ff',
        },
        {
            type: 'Home Maintenance',
            color: '#3a3a3a',
        },
    ];
    const formatUserType = (type: string) => {
        switch (type) {
            case 'Agent':
                return 'agent';
            case 'Home Inspector':
                return 'inspector';
            case 'Home Maintenance':
                return 'maintenance';
            case 'Home Appraiser':
                return 'appraiser';
            default:
                return type;
        }
    };
    const disableSignUp =
        signUpType.type === 'Agent'
            ? !userInfo.name ||
              !userInfo.phoneNumber ||
              !userInfo.email ||
              !userInfo.password ||
              !userInfo.licenseNumber ||
              !userInfo.licenseState ||
              !userInfo.agentMlsId
            : !userInfo.name ||
              !userInfo.phoneNumber ||
              !userInfo.email ||
              !userInfo.licenseNumber ||
              !userInfo.password;
    const disableLogin = !userInfo.password || !userInfo.email;
    return (
        <div className={styles.root}>
            {!!signUpType.type ? (
                <div>
                    <div
                        className={styles.backContainer}
                        onClick={() => {
                            setSignUpType({});
                            setUserInfo({ agentMlsId: (Math.random() * 10000000000).toFixed(0) });
                        }}
                    >
                        <img src={BackIcon} className={styles.backIcon} />
                        <span> Back </span>
                    </div>
                    <div className={styles.signUpContainer}>
                        <div>
                            Hello{' '}
                            {signUpType.type === 'Home Maintenance'
                                ? 'Maintenance Worker'
                                : signUpType.type}
                        </div>
                        <input
                            placeholder={'First & Last Name'}
                            onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
                            className={styles.inputStyle}
                        />
                        <input
                            placeholder={'Mobile Phone Number'}
                            onChange={(e) => {
                                setUserInfo({
                                    ...userInfo,
                                    phoneNumber: scrubAndFormatPhoneNumber(e.target.value),
                                });
                            }}
                            className={styles.inputStyle}
                        />
                        <input
                            placeholder={'Email'}
                            onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                            className={styles.inputStyle}
                        />
                        <input
                            placeholder={'License #'}
                            onChange={(e) =>
                                setUserInfo({ ...userInfo, licenseNumber: e.target.value })
                            }
                            className={styles.inputStyle}
                        />
                        {signUpType.type === 'Agent' && (
                            <>
                                <input
                                    placeholder={'License State'}
                                    onChange={(e) =>
                                        setUserInfo({
                                            ...userInfo,
                                            licenseState: e.target.value,
                                        })
                                    }
                                    className={styles.inputStyle}
                                />
                                <input
                                    placeholder={'Agent Mls Id'}
                                    onChange={(e) =>
                                        setUserInfo({ ...userInfo, agentMlsId: e.target.value })
                                    }
                                    className={styles.inputStyle}
                                />
                            </>
                        )}
                        <input
                            type="password"
                            placeholder={'Password'}
                            autoComplete="new-password"
                            onChange={(e) => setUserInfo({ ...userInfo, password: e.target.value })}
                            className={styles.inputStyle}
                        />
                        <button
                            className={disableSignUp ? styles.disabledStyle : styles.introButtons}
                            disabled={disableSignUp}
                            style={{
                                backgroundColor: disableSignUp ? '#d3d3d3' : signUpType.color,
                            }}
                            onClick={() =>
                                createNewUserRequested({
                                    ...userInfo,
                                    userType: formatUserType(signUpType.type),
                                })
                            }
                        >
                            Sign Up
                        </button>
                        {loginError && <div style={{ color: 'red' }}>{loginError?.toString()}</div>}
                    </div>
                </div>
            ) : loggingIn ? (
                <div>
                    <div
                        className={styles.backContainer}
                        onClick={() => {
                            setSignUpType({});
                            setLoggingIn(false);
                            setUserInfo({});
                        }}
                    >
                        <img src={BackIcon} className={styles.backIcon} />
                        <span> Back </span>
                    </div>
                    <div className={styles.signUpContainer}>
                        <div>Please enter your account info:</div>
                        <input
                            placeholder={'Email'}
                            onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                            className={styles.inputStyle}
                        />
                        <input
                            type="password"
                            placeholder={'Password'}
                            onChange={(e) => setUserInfo({ ...userInfo, password: e.target.value })}
                            className={styles.inputStyle}
                        />
                        {loginError ? <div style={{ color: 'red' }}>{loginError}</div> : null}
                        <button
                            className={disableLogin ? styles.disabledStyle : styles.introButtons}
                            disabled={disableLogin}
                            style={{
                                backgroundColor: disableLogin ? '#d3d3d3' : signUpType.color,
                            }}
                            onClick={() =>
                                userLoginRequested({
                                    ...userInfo,
                                })
                            }
                        >
                            Log In
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <div>Who are you?</div>
                    <div className={styles.buttonContainer}>
                        {userTypes.map((user) => {
                            return (
                                <button
                                    onClick={() => setSignUpType(user)}
                                    style={{ backgroundColor: user.color }}
                                    className={styles.introButtons}
                                >
                                    {user.type}
                                </button>
                            );
                        })}
                    </div>
                    <div>
                        Already Have an Account?{' '}
                        <a
                            onClick={() => setLoggingIn(true)}
                            style={{ cursor: 'pointer', color: '#986df4' }}
                        >
                            Log In
                        </a>
                    </div>
                </>
            )}
        </div>
    );
}

export const ConnectedLoginSignup = connector(LoginSignupScreen);
